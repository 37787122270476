import { useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useHistory, useLocation, useParams } from 'react-router';
import SubmitButtonsField from '../../Buttons/SubmitButtonsField';
import * as builderActions from '../../../redux/actions/workflow-builder-actions';

import { CircularProgress, Modal } from '@mui/material';

import { ReactComponent as EditIcon } from '../../../assets/icons/PencilEdit.svg';

import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/CaretLeft.svg';
import useBreadCrumb from '../../../hooks/useBreadCrumb';
import { routes } from '../../../utilities/routes';
import AlertBar from '../../sub_components/AlertBar';
import { setSnackBarIsOpen } from '../../../redux/actions/dashboard/workspaces-actions';
import WorkflowBuilderTasks from './WorkflowBuilderTasks';
import DropDown from '../../Inputs/DropDown';
import ColorPicker from '../../Others/ColorPicker';
import useModal from '../../Modal/useModal';
import EditWorkflowBuilderName from '../../Modal/EditWorkflowBuilderName';
import DummyModal from '../../Modal/DummyModal';
import EditWorkflowBuilderDescription from '../../Modal/EditWorkflowBuilderDescription';

function WorkflowBuilderEditor() {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const { BreadCrumbs } = useBreadCrumb();
	const { id } = useParams();

	const newWorkflowForm = useSelector((state) => state.WorkflowBuilder.newWorkflowForm);

	const workflowData = useSelector((state) => state.WorkflowBuilder.workflowData);

	const workflowCategories = useSelector((state) => state.WorkflowBuilder.workflowCategories);

	const publishWorkflowBuilderLoading = useSelector(
		(state) => state.WorkflowBuilder.publishWorkflowBuilderLoading,
	);

	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const clientForBuilder = location.state;

	const editModal = (modalType) => {
		ModalType(modalType);
		toggleModal();
	};

	// const onSave = () => {
	// 	dispatch(builderActions.getCompanyWorkflows());
	// 	history.push(`/Workflow-Builder`, clientForBuilder);
	// };

	const breadCrumbsClick = () => {
		dispatch(builderActions.getCompanyWorkflows());
		history.push(routes['Workflow-Builder'], clientForBuilder);
	};
	const updateBuilderData = async (payload) => {
		const [key, value] = Object.entries(payload)[0];
		try {
			const tempWorkflow = workflowData?.data[0];
			tempWorkflow.componentName = newWorkflowForm?.name.split(' ').join('_').toUpperCase();
			tempWorkflow.displayName = newWorkflowForm?.name;
			await dispatch(
				builderActions.updateWorkflowBuilderData({
					...workflowData,
					name: newWorkflowForm?.name.trim(),
					data: tempWorkflow,
				}),
			);
			dispatch(builderActions.workflowFormChangeHanlder(key, value));
		} catch (error) {
			dispatch(setSnackBarIsOpen(false, true, error.message));
			throw new Error(error.message);
		}
	};

	const saveBuilderData = async (payload) => {
		try {
			await dispatch(builderActions.updateWorkflowfromBuilder(id, payload));
			await updateBuilderData(payload);
		} catch (error) {
			dispatch(setSnackBarIsOpen(false, true, error.message));
			throw new Error(error.message);
		}
	};

	const publishWorkflow = async () => {
		dispatch(builderActions.publishBuilderDataLoading(true));

		const response = await saveBuilderData({ status: 'PUBLISHED' });

		if (response.status) {
			dispatch(builderActions.publishBuilderDataLoading(false));
			dispatch(builderActions.workflowBuilderLoading(false));
			dispatch(builderActions.getCompanyWorkflows());
			history.push(`/Workflow-Builder`, clientForBuilder);
			dispatch(builderActions.setComponent({}));
			dispatch(builderActions.resetWorkflowForm());
			dispatch(builderActions.publishBuilderDataLoading(false));
		}
	};

	useMemo(() => {
		dispatch(builderActions.getWorkflowCategories());
		dispatch(builderActions.getSpecificWorkflowById(id));
		dispatch(builderActions.getWorkflowBuilderData(id));
		dispatch(builderActions.getTaskTemplates(id));
		return () => {
			dispatch(builderActions.resetWorkflowForm());
		};
	}, [dispatch]);

	return (
		<div className='AppBody d-flex flex-column'>
			<AlertBar alertBarContainerStyle='w-100' />
			<div className='Workflow-Builder w-100'>
				<div className='workflow-builder-container h-100 w-100'>
					<div className='workflow-builder-bread-crumbs d-flex align-items-center pointer'>
						<ArrowLeftIcon style={{ marginRight: '4px' }} />
						<BreadCrumbs>
							<h2 className='HeadlineThreeBook text-left' onClick={breadCrumbsClick}>
								Workflow Library
							</h2>
						</BreadCrumbs>
					</div>
					<div
						className='workflow-builder-bar d-flex flex-row justify-content-between align-items-center'
						style={{ padding: '24px' }}
					>
						<h2 className='HeadlineThreeBold text-white'>Workflow Builder</h2>

						<div className='d-flex' style={{ gap: '16px' }}>
							{/* <SubmitButtonsField child={true}>
								<button
									type='submit'
									className='d-flex align-items-center curved'
									id='Element'
									onClick={onSave}
									style={{
										width: newWorkflowForm?.status !== 'PUBLISHED' && '210px',
									}}
								>
									{builderLoading ? (
											<CircularProgress
												size={20}
												sx={{ color: 'white' }}
												disableShrink
											/>
										) : (

									newWorkflowForm?.status === 'PUBLISHED'
										? 'Save'
										: 'Save Changes to Draft'

									)}
								</button>
							</SubmitButtonsField> */}
							{newWorkflowForm.status !== 'PUBLISHED' && (
								<SubmitButtonsField child={true}>
									<button
										type='submit'
										className='d-flex align-items-center curved'
										id='Element'
										onClick={publishWorkflow}
										style={{ width: '240px' }}
										disabled={publishWorkflowBuilderLoading}
									>
										{publishWorkflowBuilderLoading ? (
											<CircularProgress
												size={20}
												sx={{ color: 'white' }}
												disableShrink
											/>
										) : (
											'Publish to Company Library'
										)}
									</button>
								</SubmitButtonsField>
							)}
						</div>
					</div>

					{newWorkflowForm && (
						<div className='worklfow-editor-container'>
							<div className='workflow-editor-header'>
								<div className='d-flex justify-content-between align-items-center pb-3'>
									<div className='d-flex align-items-center'>
										<span className='HeadlineOneBold mr-2'>
											{newWorkflowForm?.name}
										</span>
										<span
											className='pointer'
											onClick={() =>
												editModal('EDIT_WORKFLOW_BUILDER_NAME_MODAL')
											}
										>
											<EditIcon />
										</span>
									</div>
									<ColorPicker
										name='color'
										color={newWorkflowForm?.color || '#fe7a03'}
										label={'Choose Workflow Color'}
										header={'Workflow Color'}
										updateColor={saveBuilderData}
									/>
								</div>
								<div className='mb-2'>
									<div className='d-flex align-items-center'>
										<span className='BodyTwoLight mb-0 mr-2'>Description</span>
										<span
											className='pointer mb-1'
											onClick={() =>
												editModal('EDIT_WORKFLOW_BUILDER_DESCRIPTION_MODAL')
											}
										>
											<EditIcon width={'16'} />
										</span>
									</div>
									<span
										className='w-100 BodyTwoLight d-block p-2 text-left'
										style={{
											marginTop: '6px',
											backgroundColor: 'white',
											height: '80px',
											maxHeight: '80px',
											overflow: 'auto',
										}}
									>
										{newWorkflowForm?.description}
									</span>
								</div>

								<div className='text-left w-25 mt-2'>
									<div
										className='d-flex align-items-center'
										style={{ gap: '12px' }}
									>
										<span className='BodyTwoLight'>Category</span>
									</div>
									<CategoryDropDown
										name='category_id'
										workflowCategories={workflowCategories}
										value={newWorkflowForm?.category_id}
										onUpdate={saveBuilderData}
									/>
								</div>
							</div>
							<WorkflowBuilderTasks />
						</div>
					)}
				</div>
			</div>
			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'EDIT_WORKFLOW_BUILDER_NAME_MODAL' && (
							<DummyModal title='Workflow Name' onClose={toggleModal}>
								<EditWorkflowBuilderName
									closeModal={toggleModal}
									name={newWorkflowForm?.name}
									onSubmit={saveBuilderData}
								/>
							</DummyModal>
						)}
						{ModalName == 'EDIT_WORKFLOW_BUILDER_DESCRIPTION_MODAL' && (
							<DummyModal title='Workflow Description' onClose={toggleModal}>
								<EditWorkflowBuilderDescription
									closeModal={toggleModal}
									description={newWorkflowForm?.description}
									onSubmit={saveBuilderData}
								/>
							</DummyModal>
						)}
					</>
				</Modal>
			)}
		</div>
	);
}

export default WorkflowBuilderEditor;

const CategoryDropDown = ({ workflowCategories, value, name, onUpdate }) => {
	const [loading, setLoading] = useState(false);

	const handleCategoryChange = async (event) => {
		setLoading(true);
		let { name, value } = event.target;
		if (value === '') value = null;
		if (value !== null) value = parseInt(value);
		await onUpdate({ [name]: value });
		setLoading(false);
	};
	return (
		<>
			{loading ? (
				<div
					style={{
						height: '50%',
						width: '50%',
					}}
					className='d-flex align-items-center justify-content-center mb-1'
				>
					<CircularProgress
						style={{
							color: '#fa7b03',
							height: '20px',
							width: '20px',
						}}
					/>
				</div>
			) : (
				<DropDown
					onChange={handleCategoryChange}
					// normalDropDown
					style={{ height: '32px', textAlign: 'left' }}
					options={workflowCategories?.map((category) => {
						return {
							label: category.name,
							value: category.id,
						};
					})}
					name={name}
					value={{ value: value }}
					disableNull
				/>
			)}
		</>
	);
};
