import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './views/App';

import reportWebVitals from './reportWebVitals';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { BrowserRouter as Router } from 'react-router-dom';
// import { createStore, applyMiddleware } from 'redux';
import { createStore, applyMiddleware, compose } from 'redux';

import { Provider } from 'react-redux';
import allReducer from './redux/reducers';
import thunk from 'redux-thunk';
import MomentUtils from '@date-io/moment';
// import * as serviceWorker from './serviceWorker.';

// import { applyMiddleware, createStore, compose } from 'redux';
// import thunk from 'redux-thunk';
import moment from 'moment-timezone';
//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import posthog from 'posthog-js';
import { Buffer } from 'buffer';
import process from 'process';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.mjs`;

window.Buffer = Buffer;
window.process = process;
// import { refreshToken } from './redux/actions/auth-actions';

//Redux
// import { Provider } from 'react-redux';
// import { store, persistor } from './redux/reducers/index';
// import { PersistGate } from 'redux-persist/integration/react';

// const store = createStore(
// 	allReducer,
// 	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
moment.tz.setDefault(userTimeZone);
// moment.tz.setDefault('Etc/UTC');
// const store = createStore(allReducer, applyMiddleware(thunk));
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(allReducer, composeEnhancers(applyMiddleware(thunk)));

if (window._env_?.LOG_LEVEL === 'false') {
	console.log = () => {};
}

if (!window.location.host.includes('localhost')) {
	if (typeof window !== 'undefined') {
		posthog.init(window._env_?.REACT_APP_POSTHOG_APIKEY, {
			// api_host: 'https://app.posthog.com',
			api_host: window._env_?.REACT_POSTHOG_PROXY,
			disable_session_recording: true,
			loaded: () => {
				posthog.startSessionRecording();
			},
		});
	}
}

/**
 * TODO Configure a mechanism to refresh tokens without disturbing time tracking
 */
// const anonymousRefresh = () => {
// 	setTimeout(() => {
// 		if (store.getState().authReducer.user.accessToken) {
// 			store.dispatch(refreshToken(true));
// 		}
// 		anonymousRefresh();
// 	}, 1 * 60000); // Wait for 30 minutes
// };

// anonymousRefresh();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<Router>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<App />
			</MuiPickersUtilsProvider>
		</Router>
	</Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default store;
