import { Checkbox, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import * as authActions from '../../redux/actions/auth-actions';
import { requiredError, passwordCheckError, matchPasswordError } from '../../utilities/utilities';

const ResetPassword = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const params = props.params.resetId;

	const [isPasswordShow, setIsPasswordShow] = useState(false);

	const togglePasswordShow = () => {
		setIsPasswordShow(!isPasswordShow);
	};

	const handleSubmit = (evt) => {
		evt.preventDefault();

		dispatch(
			authActions.resetPasswordFormErrors({
				name: 'passwordErrorText',
				value: requiredError(form.password),
			}),
		);

		dispatch(
			authActions.resetPasswordFormErrors({
				name: 'confirmPasswordErrorText',
				value: requiredError(form.confirmPassword),
			}),
		);

		if (form.password) {
			dispatch(
				authActions.resetPasswordFormErrors({
					name: 'passwordErrorText',
					value: passwordCheckError(form.password),
				}),
			);
		}

		if (form.password && form.confirmPassword) {
			dispatch(
				authActions.resetPasswordFormErrors({
					name: 'confirmPasswordErrorText',
					value: matchPasswordError(form.password, form.confirmPassword),
				}),
			);
		}

		if (
			!requiredError(form.password) &&
			!requiredError(form.confirmPassword) &&
			!passwordCheckError(form.password) &&
			!matchPasswordError(form.password, form.confirmPassword)
		) {
			dispatch(
				authActions.resetPassword({
					reset_token: params,
					password: form.password,
				}),
			);
		}
	};

	useEffect(() => {
		dispatch(authActions.checkExpiryLink(params));
	}, [dispatch]);

	const form = useSelector((state) => state.authReducer.resetPasswordForm);
	const isPasswordReset = useSelector((state) => state.authReducer.isPasswordReset);
	const isLinkExpired = useSelector((state) => state.authReducer.islinkExpired);
	const errors = useSelector((state) => state.authReducer.resetPasswordFormErrors);

	const processing = useSelector((state) => state.authReducer.processingResetPassword);

	const handleChange = (event) => {
		event.preventDefault();

		const { name, value } = event.target;
		dispatch(authActions.resetPasswordFormChange({ name, value }));
	};

	if (isPasswordReset) {
		return <Redirect to='/login' />;
	}
	if (isLinkExpired?.is_valid && isLinkExpired?.is_valid) {
		return (
			<>
				<div className='LoginBox formText'>
					<div className=''>
						<h1 className='titleBold'>Reset Password</h1>
					</div>

					<form autoComplete='off' onSubmit={handleSubmit}>
						<div className=''>
							<label htmlFor='password' className=''>
								<div className='d-flex align-items-end'>
									<div className=' d-flex align-items-end'>Password &nbsp; </div>
									<div style={{ color: '#F05544' }} className=''>
										*
									</div>
								</div>
							</label>
							<div>
								<input
									type={isPasswordShow ? 'text' : 'password'}
									id='password'
									name='password'
									value={form.password}
									onChange={handleChange}
								/>
							</div>
							<span className='m8 error'>{errors.passwordErrorText}</span>
						</div>
						<div className=''>
							<label htmlFor='confirmPassword' className=''>
								<div className='d-flex align-items-end'>
									<div className=' d-flex align-items-end'>
										Confirm Password &nbsp;{' '}
									</div>
									<div style={{ color: '#F05544' }} className=''>
										*
									</div>
								</div>
							</label>
							<div>
								<input
									type={isPasswordShow ? 'text' : 'password'}
									id='confirmPassword'
									name='confirmPassword'
									value={form.confirmPassword}
									onChange={handleChange}
								/>
							</div>
							<span className='error'>{errors.confirmPasswordErrorText}</span>
						</div>

						<br />

						<div className=' d-flex flex-row align-items-center'>
							<Checkbox
								sx={{
									color: '#EAEAEA',
									'&.Mui-checked': {
										color: '#FB7A03',
									},
									'&.MuiCheckbox-root': {
										'&:hover': {
											boxShadow: 'none',
											backgroundColor: 'transparent !important',
										},
									},
								}}
								style={{
									padding: 0,
								}}
								checked={isPasswordShow}
								onChange={togglePasswordShow}
							/>
							<label style={{ color: '#333333' }} className='formText p-0 m-0 ml-1'>
								Show password
							</label>
						</div>

						<div className='m16'>
							<div className='dGrid'>
								<button
									type='submit'
									value='Submit'
									className=''
									disabled={processing}
								>
									{processing ? (
										<CircularProgress
											size={22}
											sx={{ color: 'white' }}
											// disableShrink
										/>
									) : (
										'Continue'
									)}
								</button>
							</div>
						</div>
						<span className='error'>{errors.errorText}</span>
					</form>

					<div className=''>
						<div>
							<div className='m24' style={{ borderTop: '1px solid #28383C ' }}></div>
						</div>
					</div>
					<div className='dfCenter m16'>
						<div className=''>
							<button
								onClick={() => {
									history.push('/login');
								}}
								style={{
									color: '#FB7A03',
									fontSize: '14px',
									lineHeight: '16px',
									fontWeight: 400,
									background: '#fffefb',
								}}
							>
								Remember your password? Login
							</button>
						</div>
					</div>
				</div>
			</>
		);
	}
	return (
		<div>
			<div
				style={{
					paddingInline: '8px',
					height: '100px',
					borderRadius: '8px 8px 0px 0px',
					backgroundColor: '#E8EAEA',
				}}
				className='d-flex flex-column align-items-center justify-content-center'
			>
				<span className='HeadlineThreeBook'>Link Expired</span>
				<span className='BodyTwoLight'>
					The link has expired. Please reach out to the sender for a new invitation.
				</span>
			</div>
			<div
				style={{
					backgroundColor: 'white',
					borderRadius: '0px 0px 8px 8px',
					padding: '8px',
				}}
				className='d-flex justify-content-end'
			>
				<button
					style={{
						color: '#333',
						backgroundColor: '#E8EAEA',
						padding: '0px 25px',
						borderRadius: '16px',
					}}
					className='BodyTwoBold'
					onClick={() => {
						window.close();
					}}
				>
					Cancel
				</button>
			</div>
		</div>
	);
};
export default ResetPassword;
