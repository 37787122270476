/* eslint-disable no-mixed-spaces-and-tabs */
import * as Actions from '../actions/time-reports-actions-v2';

const initialState = {
	loading: true,
	userDetails: {},
	users: [],
	clients: [],
	userProfiles: [],
	clientProfiles: [],
	userRole: [],
	clientDeatils: {},
	user_budgeted_hours: 0,
	tasksLoading: false,
	tasks: [],
	initialLoading: true,
	timeReportsLoading: false,
};

const TimeReportsV2 = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case Actions.GET_USERS_START:
			return (state = {
				...state,
				users: [],
				loading: true,
			});
		case Actions.GET_USERS_END:
			return (state = {
				...state,
				loading: false,
				users: payload?.map((user) => {
					return {
						...user,
						budgeted_hours: parseFloat(user?.budgeted_hours) || 0,
					};
				}),
			});
		case Actions.GET_USERS_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.GET_CLIENTS_START:
			return (state = {
				...state,
				client: [],
				loading: true,
			});
		case Actions.GET_CLIENTS_END:
			return (state = {
				...state,
				loading: false,
				clients: payload
					?.filter((client) => {
						return Boolean(client.budgeted_hours != 0 || client.consumed_time != 0);
					})
					?.map((client) => {
						return {
							...client,
							budgeted_hours: parseFloat(client?.budgeted_hours) || 0,
							consumed_time: client.consumed_time / 3600000,
						};
					})
					?.sort?.((a, b) =>
						(a.dba == '' ? a.company_name : a.dba).localeCompare(
							b.dba == '' ? b.company_name : b.dba,
						),
					),
			});
		case Actions.GET_USERS_PROFILES_START:
			return (state = {
				...state,
				loading: true,
			});
		case Actions.GET_USERS_PROFILES_END:
			return (state = {
				...state,
				userProfiles: payload,
			});
		case Actions.GET_USERS_PROFILES_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.GET_CLIENT_PROFILES_START:
			return (state = {
				...state,
				loading: true,
			});
		case Actions.GET_CLIENT_PROFILES_END:
			return (state = {
				...state,
				clientProfiles: payload?.sort?.((a, b) =>
					(a.dba == '' ? a.company_name : a.dba).localeCompare(
						b.dba == '' ? b.company_name : b.dba,
					),
				),
			});
		case Actions.GET_CLIENT_PROFILES_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.GET_USER_DETAILS_START:
			return (state = {
				...state,
				loading: payload,
			});
		case Actions.GET_USER_DETAILS_END:
			return (state = {
				...state,
				loading: false,
				userDetails: {
					user_id: payload?.id,
					full_name: payload?.full_name,
					user_budgeted_hours: payload.total_budgeted_hours,
					user_consumed_time: payload?.total_logged_time,
					user_meetings:
						payload?.user_meetings?.length > 0
							? {
									budgeted_hours: parseFloat(
										payload.user_meetings?.reduce(
											(acc, meeting) =>
												acc +
												(meeting?.budgeted_hours
													? meeting?.budgeted_hours
													: 0),
											0,
										),
									),
									consumed_time: parseFloat(
										payload?.user_meetings?.reduce(
											(acc, meeting) =>
												acc +
												(meeting?.consumed_time
													? meeting?.consumed_time
													: 0),
											0,
										),
									),
							  }
							: null,
					clients: payload.clients
						?.sort?.((a, b) => a.client_name.localeCompare(b.client_name))
						?.map((client) => {
							return {
								...client,
								meetings:
									client?.meetings?.length > 0
										? {
												budgeted_hours: parseFloat(
													client?.meetings?.reduce(
														(acc, meeting) =>
															acc +
															(meeting?.budgeted_hours
																? meeting?.budgeted_hours
																: 0),
														0,
													),
												),
												consumed_time: client?.meetings?.reduce(
													(acc, meeting) =>
														acc +
														(meeting?.consumed_time
															? meeting?.consumed_time
															: 0),
													0,
												),
										  }
										: null,
								workflow_my_tasks:
									client?.workflow_my_tasks?.length > 0
										? {
												budgeted_hours: parseFloat(
													client?.workflow_my_tasks?.reduce(
														(acc, workflow_my_task) =>
															acc +
															(workflow_my_task?.budgeted_hours
																? workflow_my_task?.budgeted_hours
																: 0),
														0,
													),
												),
												consumed_time: parseFloat(
													client?.workflow_my_tasks?.reduce(
														(acc, workflow_my_task) =>
															acc +
															(workflow_my_task?.consumed_time
																? workflow_my_task?.consumed_time
																: 0),
														0,
													),
												),
										  }
										: null,
							};
						}),
				},
			});
		case Actions.GET_USER_DETAILS_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.GET_CLIENT_DETAILS_START:
			return (state = {
				...state,
				loading: payload,
			});
		case Actions.GET_CLIENT_DETAILS_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.GET_CLIENT_DETAILS_END:
			return (state = {
				...state,
				loading: false,
				clientDeatils: {
					client_id: payload?.data?.id,
					dba: payload?.data?.dba,
					company_name: payload?.data?.company_name,
					client_consumed_time: payload?.data?.total_logged_time,
					team_id: payload?.data?.team_id,
					users: payload?.data?.teams
						?.map((team) => team.users)
						?.flat()
						?.map((user) => {
							return {
								...user,
								user_roles: payload?.user_roles
									?.filter((item) => {
										if (item.user_id == user.user_id) {
											return item;
										}
									})
									?.reduce(
										(acc, role) => {
											acc.user_id = role.user_id;
											acc.scopes.push(
												...(role.role_scopes?.map((scope) => ({
													role_id: role?.role_id,
													team_id: scope?.team_id,
												})) || []),
											);
											return acc;
										},
										{ user_id: null, scopes: [] },
									),
								role_id: payload?.user_roles.find(
									(role) => role.user_id === user.user_id,
								)?.role_id,
								workflows: user?.workflows?.map((workflow) => {
									return {
										...workflow,
										budgeted_hours: parseFloat(workflow?.budgeted_hours) || 0,
										consumed_time:
											parseFloat(workflow?.consumed_time / 3600000) || 0,
									};
								}),
								workflow_my_tasks: user?.workflow_my_tasks?.map(
									(workflow_my_task) => {
										return {
											...workflow_my_task,
											budgeted_hours:
												parseFloat(workflow_my_task?.budgeted_hours) || 0,
											consumed_time:
												parseFloat(
													workflow_my_task?.consumed_time / 3600000,
												) || 0,
										};
									},
								),
								meetings: user?.meetings?.map((meeting) => {
									return {
										...meeting,
										budgeted_hours: parseFloat(meeting?.budgeted_hours) || 0,
										consumed_time:
											parseFloat(meeting?.consumed_time / 3600000) || 0,
									};
								}),
							};
						})
						?.sort((a, b) => a.full_name.localeCompare(b.full_name)),
				},
			});
		case Actions.GET_USER_ROLES_END:
			return (state = {
				...state,
				userRole: payload,
				loading: false,
			});
		case Actions.SET_USER_BUDGETED_HOURS:
			return (state = {
				...state,
				user_budgeted_hours: payload,
			});
		case Actions.SET_TASKS_LOADING:
			return (state = {
				...state,
				tasksLoading: payload,
			});
		case Actions.GET_TASKS_END:
			return (state = {
				...state,
				tasks: payload.map((task) => {
					return {
						...task,
						budgeted_hours: parseFloat(task.budgeted_hours),
						consumed_time: parseFloat(task.consumed_time / 3600000),
					};
				}),
			});
		case Actions.RESET_TASKS:
			return (state = {
				...state,
				tasks: [],
			});
		case Actions.UPDATE_LOGGED_TIME:
			return (state = {
				...state,
				tasks: state.tasks.map((task) => {
					if (task.id == payload.task_id) {
						return {
							...task,
							consumed_time: payload.value,
						};
					} else return task;
				}),
			});
		case Actions.SET_INITIAL_LOADING:
			return (state = {
				...state,
				initialLoading: payload,
			});
		case Actions.SET_TIME_REPORTS_LOADING:
			return (state = {
				...state,
				timeReportsLoading: payload,
			});
		// case Actions.SET_LOOGED_TIME:
		// 	return (state) ={
		// 		...state,
		// 		loggedTime:payload
		// 	};
		default:
			return state;
	}
};
export default TimeReportsV2;
