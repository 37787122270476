import { useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { CircularProgress, ClickAwayListener } from '@mui/material';

function ColorPicker({ color, label, updateColor, header, name }) {
	const [newColor, setNewColor] = useState();
	const [isColorPicker, setIsColorPicker] = useState(false);
	const timeoutRef = useRef(null);

	const [formControl, setFormControl] = useState({
		loading: false,
		error: '',
	});

	const handleUpdateColor = async (updatedColor) => {
		setFormControl({
			...formControl,
			loading: true,
		});
		try {
			await updateColor({ [name]: updatedColor });
		} catch (error) {
			setNewColor(color);
		} finally {
			setFormControl({
				...formControl,
				loading: false,
			});
		}
	};

	const showColorPicker = () => {
		setIsColorPicker(true);
	};

	const colorChangeHandler = (color) => {
		setNewColor(color);
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		timeoutRef.current = setTimeout(() => {
			handleUpdateColor(color);
		}, 1000);
	};

	useEffect(() => {
		setNewColor(color);
	}, [color]);

	useEffect(() => {
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, []);

	return (
		<div
			className='d-flex align-items-center'
			style={{
				position: 'relative',
			}}
		>
			<span onClick={showColorPicker} className='BodyTwoLight pointer primary-text mr-2'>
				{label}
			</span>
			{formControl?.loading ? (
				<CircularProgress size={20} sx={{ color: '#fb7a03' }} disableShrink />
			) : (
				<div
					style={{
						width: '24px',
						height: '24px',
						borderRadius: '50%',
						backgroundColor: newColor,
						cursor: 'pointer',
					}}
					onClick={showColorPicker}
				></div>
			)}
			{isColorPicker && (
				<ClickAwayListener onClickAway={() => setIsColorPicker(false)}>
					<div className='color-picker d-flex flex-column align-items-center'>
						<div className='w-100 color-picker-header'>
							<h2 className='HeadlineThreeBold text-left'>{header}</h2>
						</div>
						<div className='d-flex align-items-center color-picker-body'>
							<SketchPicker
								width='270px'
								onChangeComplete={(color) => colorChangeHandler(color.hex)}
								onChange={(color) => setNewColor(color.hex)}
								disableAlpha
								color={newColor}
							/>
						</div>
					</div>
				</ClickAwayListener>
			)}
		</div>
	);
}

export default ColorPicker;
