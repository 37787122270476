import * as Actions from '../actions/auth-actions';

const initialState = {
	user: {
		tenantId: null,
		userId: null,
		accessToken: null,
		tenantIds: [],
		userRole: null,
	},

	userDetails: {},
	loginForm: {
		email: '',
		password: '',
	},
	loginFormErrors: {
		errorText: false,
		emailErrorText: false,
		passwordErrorText: false,
	},
	loginFormLoading: false,
	activateAccountLoading: true,
	isAccountActivated: false,
	signUpForm: {
		full_name: '',
		company: '',
		email: '',
		password: '',
		confirmPassword: '',
		country: '',
		currency: '',
	},
	signUpFormErrors: {
		errorText: false,
		fullNameErrorText: false,
		countryErrorText: false,
		currencyErrorText: false,
		companyErrorText: false,
		passwordErrorText: false,
		confirmPasswordErrorText: false,
		emailErrorText: false,
	},
	signUpFormLoading: false,
	isTenantSubscribed: null,
	subscriptionLoading: true,
	isCheckingSubscription: false,
	subscriptions: [],
	forgotPasswordForm: {
		email: '',
	},
	forgotPasswordFormErrors: {
		emailErrorText: false,
		errorText: false,
	},
	isRequestResetPassword: false,
	resetPasswordForm: {
		password: '',
		confirmPassword: '',
	},
	resetPasswordFormErrors: {
		passwordErrorText: false,
		confirmPasswordErrorText: false,
		errorText: false,
	},
	isPasswordReset: false,
	processingResetPassword: false,
	actionCenterModal: false,
	passwordChanged: {
		isSuccessful: null,
		message: null,
	},
	lastError: null,
	isFeatureFlag: false,
	stripe_pricings: [],
	userStatus: {},
    islinkExpired: {}
};

const AuthReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case Actions.HANDLE_ANON_REFRESH_TOKEN:
			return (state = {
				...state,
				user: {
					...state.user,
					accessToken: payload.access_token,
				},
			});
		case Actions.HANDLE_REFRESH_TOKEN:
			return (state = {
				...state,
				user: {
					...state.user,
					tenantId: payload.tenant_id,
					userId: payload.user_id,
					accessToken: payload.access_token,
					tenantIds: payload.tenant_ids,
					userRole: payload.role,
					user_roles: payload?.user_roles,
					_v: payload?._v,
				},
				// isCheckingSubscription: true,
			});

		case Actions.LOGIN_FORM_CHANGE:
			return (state = {
				...state,
				loginForm: {
					...state.loginForm,
					[payload.name]: payload.value,
				},
			});

		case Actions.SET_LATEST_ERROR:
			return (state = {
				...state,
				lastError: payload,
			});
		case Actions.HANDLE_SUBSCRIPTION:
			return (state = {
				...state,
				isTenantSubscribed: payload,
			});
		case Actions.SET_ACTION_CENTER_MODAL:
			return (state = {
				...state,
				actionCenterModal: payload,
			});
		case Actions.HANDLE_LOGIN_START:
			return (state = {
				...state,
				loginFormLoading: true,
				loginFormErrors: { ...initialState.loginFormErrors },
			});

		case Actions.HANDLE_LOGIN:
			console.log('user', payload);
			return (state = {
				...state,
				user: {
					...state.user,
					tenantId: payload.tenant_id,
					userId: payload.user_id,
					accessToken: payload.access_token,
					tenantIds: payload.tenant_ids,
					userRole: payload.role,
					user_roles: payload?.user_roles,
					_v: payload?._v,
				},
				loginFormLoading: false,
			});

		case Actions.HANDLE_LOGIN_FAILED:
			return (state = {
				...state,
				loginFormErrors: {
					...state.loginFormErrors,
					errorText: payload,
				},
				loginFormLoading: false,
			});

		case Actions.SET_LOGIN_FORM_ERRORS:
			return (state = {
				...state,
				loginFormErrors: {
					...state.loginFormErrors,
					[payload.name]: payload.value,
				},
			});

		case Actions.ACTIVATE_ACCOUNT_START:
			return (state = {
				...state,
				activateAccountLoading: true,
			});

		case Actions.ACTIVATE_ACCOUNT_END:
			return (state = {
				...state,
				activateAccountLoading: false,
				isAccountActivated: 'true',
			});

		case Actions.ACTIVATE_ACCOUNT_FAILED:
			return (state = {
				...state,
				isAccountActivated: 'false',
				activateAccountLoading: false,
			});

		case Actions.SIGN_UP_FORM_CHANGE:
			return (state = {
				...state,
				signUpForm: {
					...state.signUpForm,
					[payload.name]: payload.value,
				},
			});

		case Actions.SET_SIGN_UP_FORM_ERRORS:
			return (state = {
				...state,
				signUpFormErrors: {
					...state.signUpFormErrors,
					[payload.name]: payload.value,
				},
			});

		case Actions.HANDLE_REGISTER:
			return (state = {
				...state,
				user: {
					...state.user,
					accessToken: payload.access_token,
					userId: payload.user_id,
					tenantId: payload.tenant_id,
					tenantIds: payload.tenant_ids,
					userRole: payload.role,
					user_roles: payload?.user_roles,
					_v: payload?._v,
				},
				signUpFormLoading: false,
			});

		case Actions.RESET_SIGN_UP_FORM:
			return (state = {
				...state,
				signUpForm: { ...initialState.signUpForm },
				signUpFormErrors: { ...initialState.signUpFormErrors },
				signUpFormLoading: false,
			});

		case Actions.HANDLE_REGISTER_START:
			return (state = {
				...state,
				signUpFormLoading: true,
			});

		case Actions.HANDLE_REGISTER_FAILED:
			return (state = {
				...state,
				signUpFormLoading: false,
			});

		case Actions.RESET_LOGIN_FORM:
			return (state = {
				...state,
				loginForm: { ...initialState.loginForm },
				loginFormErrors: { ...initialState.loginFormErrors },
				loginFormLoading: false,
			});

		case Actions.GET_SUBSCRIPTIONS:
			return (state = {
				...state,
				isTenantSubscribed: true,
				isCheckingSubscription: false,
				subscriptions: payload,
			});
		case Actions.RENEW_SUBSCRIPTION: {
			return (state = {
				...state,
				subscriptions: {
					...state.subscriptions,
					status: payload.status,
				},
			});
		}
		case Actions.GET_STRIPE_PRICINGS: {
			return (state = {
				...state,
				stripe_pricings: payload,
			});
		}
		case Actions.CANCEL_SUBSCRIPTION: {
			return (state = {
				...state,
				subscriptions: {
					...state.subscriptions,
					status: payload?.status,
				},
			});
		}
		case Actions.GET_SUBSCRIPTIONS_FAILED:
			return (state = {
				...state,
				subscriptionLoading: false,
			});

		case Actions.ADD_SUBSCRIPTION:
			return (state = {
				...state,
				isTenantSubscribed: true,
			});

		case Actions.FORGOT_PASSWORD_FORM_CHANGE:
			return (state = {
				...state,
				forgotPasswordForm: {
					...state.forgotPasswordForm,
					[payload.name]: payload.value,
				},
			});

		case Actions.FORGOT_PASSWORD_FORM_ERRORS:
			return (state = {
				...state,
				forgotPasswordFormErrors: {
					...state.forgotPasswordFormErrors,
					[payload.name]: payload.value,
				},
			});

		case Actions.REQUEST_RESET_PASSWORD_FAILED:
			return (state = {
				...state,
				forgotPasswordFormErrors: {
					...state.forgotPasswordFormErrors,
					errorText: payload,
				},
			});

		case Actions.REQUEST_RESET_PASSWORD_START:
			return (state = {
				...state,
				forgotPasswordFormErrors: {
					...initialState.forgotPasswordFormErrors,
				},
			});

		case Actions.REQUEST_RESET_PASSWORD_END:
			return (state = {
				...state,
				isRequestResetPassword: true,
			});

		case Actions.RESET_PASSWORD_FORM_CHANGE:
			return (state = {
				...state,
				resetPasswordForm: {
					...state.resetPasswordForm,
					[payload.name]: payload.value,
				},
			});

		case Actions.RESET_PASSWORD_FORM_ERRORS:
			return (state = {
				...state,
				resetPasswordFormErrors: {
					...state.resetPasswordFormErrors,
					[payload.name]: payload.value,
				},
			});

		case Actions.RESET_PASSWORD_START:
			return (state = {
				...state,
				resetPasswordFormErrors: {
					...initialState.resetPasswordFormErrors,
				},
				processingResetPassword: true,
			});

		case Actions.RESET_PASSWORD_FAILED:
			return (state = {
				...state,
				resetPasswordFormErrors: {
					...state.resetPasswordFormErrors,
					errorText: payload,
				},
				processingResetPassword: false,
			});

		case Actions.RESET_PASSWORD_END:
			return (state = {
				...state,
				isPasswordReset: true,
				processingResetPassword: false,
			});
        case Actions.LINK_EXPIRED_STATUS:
            console.log(payload,'payload123');
            return state = {
                ...state,
                islinkExpired: payload
            };
		case Actions.CURRENT_USER_DETAILS:
			return (state = {
				...state,
				userDetails: payload,
			});
		case Actions.CHANGE_PASSWORD:
			return (state = {
				...state,
				passwordChanged: {
					...state.passwordChanged,
					isSuccessful: true,
					message: 'Password Updated Successfuly',
				},
			});
		case Actions.CHANGE_PASSWORD_FAILED:
			return (state = {
				...state,
				passwordChanged: {
					...state.passwordChanged,
					isSuccessful: false,
					message: payload,
				},
			});
		case Actions.SET_FEATURE_FLAG:
			return (state = {
				...state,
				isFeatureFlag: payload,
			});
		case Actions.SET_USER_ONLINE_STATUS:
			return (state = {
				...state,
				userStatus: payload,
			});
		default:
			return state;
	}
};

export default AuthReducer;
