import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as clientActions from '../../redux/actions/client-actions';
import DocumentStorage from './DocumentStorage';
import useModal from '../Modal/useModal';
import CreateFolderModal from '../Modal/CreateFolderModal';
import UploadFileModal from '../Modal/UploadFileModal';
import ProgressBarModal from '../Modal/ProgressBarModal';
import FileExistModal from '../Modal/FileExistModal';
import DocumentStorageHeader from './DocumentStorageHeader';
import { Member } from '../../utilities/utilities';
import { CircularProgress, Tooltip } from '@mui/material';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';

function DocumentManagement({ client, clientWorkflow, showCheckBox, sharedAccess }) {
	const headers = [
		{ key: 'lastModified', label: 'Last Modified', width: '150px' },
		{ key: 'fileSize', label: 'File Size', width: '150px' },
		{ key: 'action', label: 'Action', width: '60px' },
	];
	const disabled = clientWorkflow?.status === 'draft' ? true : false;

	const defaultFolderId = sharedAccess
		? client?.folder_id
		: clientWorkflow?.attachment?.sections?.folder === null
		? client?.folder_id
		: clientWorkflow?.attachment?.sections?.folder?.default_folder_id;

	const dispatch = useDispatch();
	const folderRef = useRef();
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const [selectedItem, setSelectedItem] = useState(null);
	const [progressBar, setProgressBar] = useState(false);
	const [alertModal, setAlertModal] = useState(false);
	const [files, setFiles] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const user = useSelector((state) => state.authReducer.user);

	let folderData = useSelector((state) => state.clientReducer.folderData);

	const role_id = user?.user_roles?.find((item) =>
		item.role_scopes?.some(
			(role) => role.team_id === client?.team_client_permissions[0]?.team_id,
		),
	)?.role_id;

	if (role_id === Member.teamMemberId) {
		folderData = folderData?.filter(
			(folder) =>
				folder?.access_control?.role_level === Member.teamMemberId ||
				folder?.access_control?.role_level === Member.externalCollaborator,
		);
	}

	const uniqueFolderData = folderData?.filter((folder, index, self) => {
		return index === self.findIndex((f) => f.id === folder.id);
	});

	const openProgressModal = () => {
		setProgressBar(true);
		dispatch(clientActions.resetUploadState());
	};

	const closeProgressModal = () => {
		setProgressBar(false);
		dispatch(clientActions.resetUploadState());
	};

	const handleAlertModal = () => {
		setAlertModal(!alertModal);
	};

	const handleModalOpen = (type) => () => {
		ModalType(type);
		toggleModal();
	};

	const handleFolderClick = (e, folderId) => {
		e.preventDefault();
		e.stopPropagation();

		dispatch(clientActions.getAllFoldersAndFiles(folderId));
	};

	useEffect(() => {
		let timeoutId;

		const fetchData = () => {
			if (client?.folder_id) {
				setIsLoading(true);
				dispatch(clientActions.getSpecificFolder(client.folder_id))
					.then((data) => {
						if (!data?.id) {
							timeoutId = setTimeout(() => {
								fetchData();
							}, 2500);
						}
						setIsLoading(false);
					})
					.catch((error) => {
						dispatch(setSnackBarIsOpen(false, true, error.message));

						timeoutId = setTimeout(() => {
							fetchData();
						}, 2500);
						setIsLoading(false);
					});
			}
		};

		fetchData();

		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, [client, dispatch, clientWorkflow?.id]);

	return (
		<>
			<section className='client-profile' style={{ gap: '16px' }}>
				<DocumentStorageHeader
					handleModalOpen={handleModalOpen}
					disabled={disabled}
					hideText={sharedAccess}
				/>
				<Tooltip
					title={
						disabled
							? 'This will be enabled once the workflow is activated. To view all folders and files, click on Shared Access in the side navigation bar'
							: ''
					}
				>
					{isLoading ? (
						<div>
							<CircularProgress
								style={{
									color: '#fa7b03',
									height: '35px',
									width: '35px',
								}}
							/>
						</div>
					) : (
						<div className='Profile-Content'>
							<DocumentStorage
								headers={headers}
								tempFolderData={uniqueFolderData}
								disabled={disabled}
								showCheckBox={showCheckBox}
								handleFolderClick={handleFolderClick}
								defaultFolderId={defaultFolderId}
								selectedItem={selectedItem}
								clientWorkflow={clientWorkflow}
								setSelectedItem={setSelectedItem}
								taskModal={false}
								folderRef={folderRef}
								handleAlertModal={handleAlertModal}
							/>
						</div>
					)}
				</Tooltip>
			</section>

			{ModalName == 'CREATE_FOLDER' && (
				<CreateFolderModal
					modalIsOpen={modalIsOpen}
					toggleModal={toggleModal}
					ModalName={ModalName}
					ModalType={ModalType}
					item={{ id: defaultFolderId }}
					title='Create Folder'
				/>
			)}

			{ModalName == 'UPLOAD_FILES' && (
				<UploadFileModal
					modalIsOpen={modalIsOpen}
					toggleModal={toggleModal}
					setFiles={setFiles}
					openProgressModal={openProgressModal}
					closeProgressModal={closeProgressModal}
					handleAlertModal={handleAlertModal}
					client={client}
					clientWorkflow={clientWorkflow}
				/>
			)}
			{progressBar && (
				<ProgressBarModal
					onClose={closeProgressModal}
					files={files}
					modalIsOpen={progressBar}
				/>
			)}
			{alertModal && (
				<FileExistModal modalIsOpen={alertModal} toggleModal={handleAlertModal} />
			)}
		</>
	);
}

export default memo(DocumentManagement);
