import { instance, secure_instance } from '../../axios/axios-config';
import { setCookie, getCookie } from '../../utilities/utilities';
import { setSnackBarIsOpen } from './dashboard/workspaces-actions';

export const HANDLE_ANON_REFRESH_TOKEN = '[AUTH] HANDLE_ANON_REFRESH_TOKEN';
export const HANDLE_REFRESH_TOKEN = '[AUTH] HANDLE_REFRESH_TOKEN';
export const HANDLE_LOGIN = '[AUTH] HANDLE_LOGIN';
export const HANDLE_LOGIN_FAILED = '[AUTH] HANDLE_LOGIN_FAILED';
export const LOGIN_FORM_CHANGE = '[AUTH] LOGIN_FORM_CHANGE';
export const HANDLE_LOGIN_START = '[AUTH] HANDLE_LOGIN_START';
export const SET_LOGIN_FORM_ERRORS = '[AUTH] SET_LOGIN_FORM_ERRORS';
export const ACTIVATE_ACCOUNT_END = '[AUTH] ACTIVATE_ACCOUNT_END';
export const ACTIVATE_ACCOUNT_START = '[AUTH] ACTIVATE_ACCOUNT_START';
export const ACTIVATE_ACCOUNT_FAILED = '[AUTH] ACTIVATE_ACCOUNT_FAILED';
export const SIGN_UP_FORM_CHANGE = '[AUTH] SIGN_UP_FORM_CHANGE';
export const SET_SIGN_UP_FORM_ERRORS = '[AUTH] SET_SIGN_UP_FORM_ERRORS';
export const HANDLE_REGISTER = '[AUTH] HANDLE_REGISTER';
export const RESET_SIGN_UP_FORM = '[AUTH] RESET_SIGN_UP_FORM';
export const HANDLE_REGISTER_START = '[AUTH] HANDLE_REGISTER_START';
export const HANDLE_REGISTER_FAILED = '[AUTH] HANDLE_REGISTER_FAILED';
export const RESET_LOGIN_FORM = '[AUTH] RESET_LOGIN_FORM';
export const GET_SUBSCRIPTIONS = '[AUTH] GET_SUBSCRIPTIONS';
export const GET_SUBSCRIPTIONS_FAILED = '[AUTH] GET_SUBSCRIPTIONS_FAILED';
export const ADD_SUBSCRIPTION = '[AUTH] ADD_SUBSCRIPTION';
export const FORGOT_PASSWORD_FORM_CHANGE = '[AUTH] FORGOT_PASSWORD_FORM_CHANGE';
export const FORGOT_PASSWORD_FORM_ERRORS = '[AUTH] FORGOT_PASSWORD_FORM_ERRORS';
export const REQUEST_RESET_PASSWORD_END = '[AUTH] REQUEST_RESET_PASSWORD_END';
export const RESET_PASSWORD_FORM_CHANGE = '[AUTH] RESET_PASSWORD_FORM_CHANGE';
export const REQUEST_RESET_PASSWORD_START = '[AUTH] REQUEST_RESET_PASSWORD_START';
export const REQUEST_RESET_PASSWORD_FAILED = '[AUTH] REQUEST_RESET_PASSWORD_FAILED';
export const RESET_PASSWORD_FORM_ERRORS = '[AUTH] RESET_PASSWORD_FORM_ERRORS';
export const RESET_PASSWORD_START = '[AUTH] RESET_PASSWORD_START';
export const RESET_PASSWORD_END = '[AUTH] RESET_PASSWORD_END';
export const RESET_PASSWORD_FAILED = '[AUTH] RESET_PASSWORD_FAILED';
export const CURRENT_USER_DETAILS = '[AUTH] CURRENT_USER_DETAILS';
export const SET_ACTION_CENTER_MODAL = '[AUTH] SET_ACTION_CENTER_MODAL';
export const CHANGE_PASSWORD = '[AUTH] CHANGE_PASSWORD';
export const CHANGE_PASSWORD_FAILED = '[AUTH] CHANGE_PASSWORD_FAILED';
export const SET_LATEST_ERROR = 'SET_LATEST_ERROR';
export const SET_FEATURE_FLAG = 'SET_FEATURE_FLAG';
export const RENEW_SUBSCRIPTION = 'RENEW_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const GET_STRIPE_PRICINGS = 'GET_STRIPE_PRICINGS';
export const HANDLE_SUBSCRIPTION = 'HANDLE_SUBSCRIPTION';
export const SET_USER_ONLINE_STATUS = 'SET_USER_ONLINE_STATUS';
export const LINK_EXPIRED_STATUS = '[AUTH] LINK_EXPIRED_STATUS';

export const setLatestErrorId = (lastError) => {
	return (dispatch) => {
		dispatch({
			type: SET_LATEST_ERROR,
			payload: lastError,
		});
	};
};

export const handleSubscription = (state) => {
	return (dispatch) => {
		dispatch({
			type: HANDLE_SUBSCRIPTION,
			payload: state,
		});
	};
};

export const refreshToken = (is_anonymous_refresh = false) => {
	// const { search } = useLocation();
	return async (dispatch) => {
		try {
			const request = await instance.request({
				url: 'v1/auth/refresh',
				method: 'Post',
				data: {
					refresh_token: getCookie('refresh_token'),
				},
			});

			if (!is_anonymous_refresh) {
				// dispatch(getSubscriptions());
				dispatch(getCurrentUserDetails(request?.data?.user_id));
			}

			// if (
			// 	!request?.data?.is_active
			// 	// !window.location.href.includes('activation')
			// ) {
			// 	window.location.href = '/workspace';
			// }
			// if (
			// 	!request?.data?.subscription_status &&
			// 	!window.location.href.includes('subscription') &&
			// 	!window.location.href.includes('paymentinfo')
			// ) {
			// 	window.location.href = '/workspace';
			// }
			dispatch({
				type: is_anonymous_refresh ? HANDLE_ANON_REFRESH_TOKEN : HANDLE_REFRESH_TOKEN,
				payload: request.data,
			});
		} catch (e) {
			localStorage.removeItem('active_task_id');
			window.location.href = `/login${window.location.search}`;
		}
	};
};

export const checkExpiryLink = (token) => {
	return async (dispatch) => {
		try {
			const request = await instance.request({
				url: `v1/auth/validate_token?token=${token}&type=RESET_PASSWORD`,
				method: 'GET',
			});

			dispatch({
				type: LINK_EXPIRED_STATUS,
				payload: request.data,
			});
		} catch (error) {
			console.log('errror', error);
		}
	};
};
export const changePassword = (password, new_password) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: 'v1/users/password',
				method: 'PATCH',
				data: {
					password,
					new_password,
				},
			});

			dispatch({
				type: CHANGE_PASSWORD,
				payload: request.data,
			});
			return request.data;
		} catch (error) {
			dispatch({
				type: CHANGE_PASSWORD_FAILED,
				payload: error.response?.data?.message,
			});
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};
export const setChangePasswordError = (data) => (dispatch) => {
	console.log('paylod is', data);
	dispatch({
		type: CHANGE_PASSWORD_FAILED,
		payload: data,
	});
};
export const getCurrentUserDetails = (id) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: CURRENT_USER_DETAILS,
				payload: null,
			});

			const request = await secure_instance.request({
				url: 'v1/user_profiles/' + id,
				method: 'Get',
			});

			dispatch({
				type: CURRENT_USER_DETAILS,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const resetCurrentUserDetails = () => {
	return (dispatch) => {
		dispatch({
			type: CURRENT_USER_DETAILS,
			payload: null,
		});
	};
};

export const resetUserProfile = () => {
	return (dispatch) => {
		dispatch({
			type: CURRENT_USER_DETAILS,
			payload: null,
		});
	};
};

export const login = (data, history, zapierClientId, zapierRedirectUri, zapierState) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: HANDLE_LOGIN_START,
			});

			const request = await instance.request({
				url: 'v1/auth/login',
				method: 'Post',
				data,
			});

			setCookie('refresh_token', request.data.refresh_token, 7);

			dispatch({
				type: HANDLE_LOGIN,
				payload: request.data,
			});
			console.log(zapierClientId, zapierRedirectUri, zapierState, 'fraudkuna2321');
			if (zapierClientId && zapierRedirectUri && zapierState) {
				history.push(`/oauth${window.location.search}`);
			} else if (history && history.push) {
				localStorage.removeItem('WORKSPACE_DATE');
				localStorage.removeItem('workspace_applied_filters');
				localStorage.removeItem('active_task_id');
				dispatch(getCurrentUserDetails(request?.data?.user_id));
				history.push('/workspace');
			}

			// if (request?.data?.is_active && request?.data?.subscription_status) {

			// } else {
			// 	localStorage.setItem('email', data.email);

			// 	if (
			// 		!request?.data?.is_active &&
			// 		!window.location.href.includes('activation')
			// 	) {
			// 		history && history.push('/activation');
			// 	} else if (
			// 		!request?.data?.subscription_status &&
			// 		!window.location.href.includes('subscription') &&
			// 		!window.location.href.includes('paymentinfo')
			// 	) {
			// 		history && history.push('/subscription');
			// 	}
			// }
			dispatch({
				type: RESET_LOGIN_FORM,
			});
		} catch (e) {
			dispatch({
				type: HANDLE_LOGIN_FAILED,
				payload: e.response?.data?.message,
			});
		}
	};
};

export const loginFormChange = (data) => {
	return {
		type: LOGIN_FORM_CHANGE,
		payload: data,
	};
};

export const setLoginFormErrors = (data) => {
	return {
		type: SET_LOGIN_FORM_ERRORS,
		payload: data,
	};
};

export const activate = (data) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: ACTIVATE_ACCOUNT_START,
			});

			await instance.request({
				url: 'v1/auth/activate',
				method: 'Post',
				data,
			});

			localStorage.removeItem('email');

			dispatch({
				type: ACTIVATE_ACCOUNT_END,
			});
		} catch (e) {
			dispatch({
				type: ACTIVATE_ACCOUNT_FAILED,
			});
		}
	};
};

export const requestActivate = () => {
	return async () => {
		try {
			const email = localStorage.getItem('email');

			await instance.request({
				url: '/v1/auth/activate/request',
				method: 'Post',
				data: {
					email,
				},
			});
		} catch (e) {
			//
		}
	};
};

export const signUpFormChange = (data) => {
	return {
		type: SIGN_UP_FORM_CHANGE,
		payload: data,
	};
};

export const setSignUpFormErrors = (data) => {
	return {
		type: SET_SIGN_UP_FORM_ERRORS,
		payload: data,
	};
};

export const register = (data, history, zapierClientId, zapierRedirectUri, zapierState) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: SET_SIGN_UP_FORM_ERRORS,
				payload: { name: 'errorText', value: '' },
			});

			dispatch({
				type: HANDLE_REGISTER_START,
			});

			const request = await instance.request({
				url: 'v1/auth/register',
				method: 'Post',
				data,
			});

			setCookie('refresh_token', request.data.refresh_token, 7);
			setTimeout(() => {
				localStorage.removeItem('WORKSPACE_DATE');
				localStorage.removeItem('workspace_applied_filters');
				localStorage.setItem('email', data.email);

				dispatch({
					type: HANDLE_REGISTER,
					payload: request.data,
				});

				dispatch(resetSignUpForm());

				console.log(zapierClientId, zapierRedirectUri, zapierState, 'fraudkuna2321');
				if (zapierClientId && zapierRedirectUri && zapierState) {
					history.push(`/oauth${window.location.search}`);
				} else if (history && history.push) {
					dispatch(getCurrentUserDetails(request?.data?.user_id));
					history.push('/workspace');
				}
			}, 3000);
		} catch (e) {
			dispatch({
				type: HANDLE_REGISTER_FAILED,
			});
			dispatch({
				type: SET_SIGN_UP_FORM_ERRORS,
				payload: { name: 'errorText', value: e?.response?.data?.message },
			});
		}
	};
};

export const resetSignUpForm = () => {
	return {
		type: RESET_SIGN_UP_FORM,
	};
};

export const resetLoginForm = () => {
	return {
		type: RESET_LOGIN_FORM,
	};
};

export const getSubscription = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/subscriptions/?expand=[tenant.user_roles(distinctAndActive), stripe_pricing, stripe_product]',
				method: 'Get',
			});

			dispatch({
				type: GET_SUBSCRIPTIONS,
				payload: request?.data[0],
			});
		} catch (e) {
			dispatch({
				type: GET_SUBSCRIPTIONS_FAILED,
			});
		}
	};
};

export const getStripePricings = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/stripe_products/?expand=stripe_pricings',
				method: 'GET',
			});

			console.log(request, 'request1234');

			dispatch({
				type: GET_STRIPE_PRICINGS,
				payload: request?.data,
			});
		} catch (error) {
			//
		}
	};
};

export const addSubscription = (data, setLoad) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: 'v1/subscriptions/',
				method: 'Post',
				data,
			});

			dispatch({
				type: ADD_SUBSCRIPTION,
				payload: request?.data,
			});

			setLoad && setLoad(false);
			return request.data;
		} catch (error) {
			setLoad && setLoad(false);
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const forgotPasswordFormChange = (data) => {
	return {
		type: FORGOT_PASSWORD_FORM_CHANGE,
		payload: data,
	};
};

export const setActionCenterModal = (data) => {
	return {
		type: SET_ACTION_CENTER_MODAL,
		payload: data,
	};
};

export const forgotPasswordFormErrors = (data) => {
	return {
		type: FORGOT_PASSWORD_FORM_ERRORS,
		payload: data,
	};
};

export const requestResetPassword = (data) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: REQUEST_RESET_PASSWORD_START,
			});

			const request = await instance.request({
				url: 'v1/auth/resetpassword/request',
				method: 'Post',
				data,
			});

			dispatch({
				type: REQUEST_RESET_PASSWORD_END,
				payload: request.data,
			});
		} catch (e) {
			dispatch({
				type: REQUEST_RESET_PASSWORD_FAILED,
				payload: e.response.data?.message,
			});
		}
	};
};

export const resetPasswordFormChange = (data) => {
	return {
		type: RESET_PASSWORD_FORM_CHANGE,
		payload: data,
	};
};

export const resetPasswordFormErrors = (data) => {
	return {
		type: RESET_PASSWORD_FORM_ERRORS,
		payload: data,
	};
};

export const resetPassword = (data) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: RESET_PASSWORD_START,
			});

			const request = await instance.request({
				url: 'v1/auth/resetpassword',
				method: 'Patch',
				data,
			});

			dispatch({
				type: RESET_PASSWORD_END,
				payload: request.data,
			});
		} catch (e) {
			dispatch({
				type: RESET_PASSWORD_FAILED,
				payload: e.response.data?.message,
			});
		}
	};
};

export const setFeatureFlag = (isFeatureFlag) => {
	return {
		type: SET_FEATURE_FLAG,
		payload: isFeatureFlag,
	};
};

export const cancelSubscription = (id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/subscriptions/${id}`,
				method: 'Delete',
			});

			dispatch({
				type: CANCEL_SUBSCRIPTION,
				payload: request?.data,
			});

			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const renewSubscription = (id, stripe_pricing_id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/subscriptions/${id}/renew`,
				method: 'PATCH',
				data: {
					stripe_pricing_id: stripe_pricing_id,
				},
			});

			dispatch({
				type: RENEW_SUBSCRIPTION,
				payload: request?.data,
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};
export const setUserActivity = (data, start_time) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: '/v1/workspace/user_activity',
				method: 'POST',
				data: {
					status: data,
					start_time: start_time,
				},
			});
		} catch {
			//
		}
	};
};

export const getUserActivity = () => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: '/v1/workspace/user_activity',
				method: 'GET',
			});
		} catch (error) {
			//
		}
	};
};

export const setUserOnlineStatus = (data) => {
	return {
		type: SET_USER_ONLINE_STATUS,
		payload: data,
	};
};

export const sendSignUpInvite = (email) => {
	return async (dispatch) => {
		try {
			const request = await instance.request({
				url: '/v1/auth/activate/request',
				method: 'Post',
				data: {
					email,
				},
			});

			dispatch(setSnackBarIsOpen(true, true, 'Invite sent'));
			return request?.data;
		} catch (e) {
			dispatch(setSnackBarIsOpen(true, true, 'Something went wrong. Try again!'));
			return 'Something went wrong. Try again!';
		}
	};
};
