/* eslint-disable no-mixed-spaces-and-tabs */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonSquare from '../Buttons/ButtonSquare';
import moment from 'moment-timezone';
import ContentLoader from 'react-content-loader';
import TaskModalContainer from '../sub_components/TaskModalContainer';
import * as Actions from '../../redux/actions/dashboard/workspaces-actions';
import * as userActions from '../../redux/actions/user-management.actions';
import * as ActionCenterActions from '../../redux/actions/action-center';
import * as teamActions from '../../redux/actions/team-management.actions';
import { Avatar, Tooltip, CircularProgress, Modal } from '@mui/material';
import {
	Member,
	objectBeingDragged,
	TASK_STATUSES,
	TASK__DISPLAY_STATUSES,
	dependentTaskCompleteAlert,
	convertDecimalToTime,
	SERVER_SENT_EVENTS,
	NOTIFICATION_STATUS,
	detailsModal,
	TRACKING_STATUS,
    getAvatarLabel,
} from '../../utilities/utilities';
import ContactsTable from './ContactsTable';
import ResourcesTab from './ResourcesTab';
import TimeTrackingTab from './TimeTrackingTab';
import TaskAlert from '../Alerts/TaskAlert';
import ExpandableNotesView from './ExpandableNotesView';
import NewTaskDetailsModalNotes from './NewTaskDetailsModalNotes';
import useModal from '../Modal/useModal';
import AdHocTaskDeleteModal from '../Modal/AdHocTaskDeleteModal';
import TaskModalComments from './TaskModalComments';
import DocumentStorage from '../ClientManagement/DocumentStorage';
import * as clientActions from '../../redux/actions/client-actions';
import TaskScheduling from './TaskScheduling';
import FileExistModal from '../Modal/FileExistModal';
import TimeTracking from './TimeTracking';
import useTimeZone from '../../hooks/useTimeZone';
import EventSource from '../../EventSource/EventSource';
import { evnetParseHandler } from '../../EventSource/EventSourceHandler';
import { getUserSettings } from '../../hooks/getUserSettings';
import { ReactComponent as Minimize } from '../../assets/icons/Minuswhite.svg';
import { ReactComponent as Close } from '../../assets/icons/Closewhite.svg';
import { ReactComponent as ArrowOut } from '../../assets/icons/ArrowSquareOut.svg';
import TaskDetailsBar from '../../views/TaskDetailsBar';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { useHistory } from 'react-router';
import RequestDocumentInvite from './RequestDocumentInvite';

const NewTaskDetailsModal = React.forwardRef(function NewModal(
	{ onModalClose, id, handleTaskClick, handleTaskTimerRef, handleModalMinimze },
	ref,
) {
	const dispatch = useDispatch();
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const headers = [
		{ key: 'lastModified', label: 'Last Modified', width: '100px' },
		{ key: 'uploadedBy', label: 'Uploaded By', width: '100px' },
		{ key: 'action', label: 'Action', width: '60px' },
	];
	const headersForModal = [{ key: 'lastModified', label: 'Last Modified', width: '100px' }];

	const accountTypes = [
		{
			title: 'Software',
			value: objectBeingDragged.SOFTWARE,
		},
		{
			title: 'Credit Card Account',
			value: objectBeingDragged.CREDIT_CARD_ACCOUNT,
		},
		{
			title: 'Bank Account',
			value: objectBeingDragged.BANK_ACCOUNT,
		},
		{
			title: 'Other Portals',
			value: objectBeingDragged.PAYMENT_PORTAL,
		},
	];

	//States
	const [isTaskAlert, setIsTaskAlert] = useState(false);
	const [taskCompleteAlertMessage, setTaskCompleteAlertMessage] = useState(null);
	const timeLoggedRef = useRef(0);
	const dragOffset = useRef({ x: 0, y: 0 });
	const isDrag = useRef(null);

	const [selectedItem, setSelectedItem] = useState(null);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [files, setFiles] = useState([]);
	const [alertModal, setAlertModal] = useState(false);
	const [buttonLoading, setButtonLoading] = useState({});
	const [commentLoading, setCommentLoading] = useState(false);
	const [expandView, setExpandView] = useState({
		isExpanded: false,
		content: null,
		title: '',
		name: '',
		isEdit: false,
		futureCheck: false,
	});

	const [expandComment, setExpandComment] = useState(false);
	const user = useSelector((state) => state.authReducer.user);
	const weeklyClientTasks = useSelector((state) => state.workSpaceReducer.weeklyClientTasks);
	const taskDetails = useSelector((state) => state.workSpaceReducer.newTaskDetails);
	const taskAssignees = useSelector((state) => state.workSpaceReducer.taskAssignees);
	const workflowSubmission = useSelector((state) => state.workSpaceReducer.workflowSubmission);
	const taskTimerData = useSelector((state) => state.workSpaceReducer.taskTimerData);
	const notifications = useSelector((state) => state.actionCenterReducer.notifications);
	let teamMembers = useSelector((state) => state.teamReducer.teamMembers);
	let allTenantUsers = useSelector((state) => state.userReducer.allTenantUsers);
	const loggedInUser = useSelector((state) => state.authReducer.user);
	const event_source = useSelector((state) => state.workSpaceReducer.eventSource);
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);
	const isTaskMinimized = useSelector((state) => state.workSpaceReducer.isTaskMinimized);

	const folderId =
		taskDetails?.client_workflow?.attachment?.sections?.folder?.default_folder_id || null;
	const attached_ids =
		taskDetails?.client_workflow?.attachment?.sections?.folder?.attached_ids || null;

	const today_date = moment().format('YYYY-MM-DD');
	const task_date = moment(taskDetails?.extended_date || taskDetails?.start_date).format(
		'YYYY-MM-DD',
	);

	const assignee = taskAssignees?.length > 0 ? taskAssignees[0] : null;

	if (assignee !== null && !teamMembers?.some((member) => member.user_id == assignee.user_id)) {
		teamMembers = [
			...teamMembers,
			{
				...assignee?.user_profile,
				user_id: assignee?.user_id,
				name: assignee?.user_profile?.full_name,
			},
		];
	}

	const isAdhoc = taskDetails?.my_task?.task_type == 'ADHOC' || taskDetails?.type == 'WORKFLOW';

	const toggleTaskAlert = () => {
		setIsTaskAlert(!isTaskAlert);
	};

	const handleModalOpen = (type) => () => {
		ModalType(type);
		toggleModal();
	};

	const handleFolderClick = (e, folderId) => {
		e.preventDefault();
		e.stopPropagation();
		dispatch(clientActions.getAllFoldersAndFiles(folderId));
	};
	const handleAlertModal = () => {
		setAlertModal(!alertModal);
	};

	const isDeleteAdhocAllowed =
		taskDetails?.my_task?.task_type == 'ADHOC' &&
		taskDetails?.my_task?.creator_id == loggedInUser?.userId;

	const unreadNotifications = notifications?.filter(
		(notification) =>
			notification?.metadata?.task_id === taskDetails?.id &&
			notification?.status === 'UNREAD',
	);

	if (unreadNotifications.length > 0) {
		const notificationIds = unreadNotifications?.map((notification) => notification?.id);
		dispatch(ActionCenterActions.markNotification(notificationIds, NOTIFICATION_STATUS.READ))
			.then((response) => {
				const notifiactionIds = response;
				const updatedNotification = notifications?.map((notification) => {
					if (notifiactionIds?.includes(notification?.id)) {
						return {
							...notification,
							status: NOTIFICATION_STATUS.READ,
						};
					} else return notification;
				});
				dispatch(ActionCenterActions.updateNotifications(updatedNotification));
			})
			.catch((error) => {
				dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
			});
	}

	if (allTenantUsers?.length > 1) {
		allTenantUsers = allTenantUsers
			.filter((a) => a?.id !== user.userId)
			.map((u) => {
				return {
					id: u?.id,
					display: u.full_name,
				};
			});
	}

	const task =
		assignee?.user_profile?.id === user?.userId
			? taskDetails
			: taskDetails?.status === TASK_STATUSES.APPROVAL_PENDING &&
			  taskDetails?.approval_from === user?.userId
			? taskDetails
			: null;
	const isApprover =
		taskDetails?.status === TASK_STATUSES.APPROVAL_PENDING &&
		taskDetails?.approval_from === user?.userId;

	var consumedTime = undefined;
	if (task || isApprover) {
		const taskTimer = task?.task_timers?.find((timer) => {
			return (
				timer?.user_id === user?.userId &&
				timer?.task_id === task?.id &&
				today_date === moment(timer?.logged_date).format('YYYY-MM-DD')
			);
		});
		if (taskTimer) {
			consumedTime = taskTimer?.consumed_time;
			// timeLoggedRef.current = consumedTime;
		}
	}

	const totalContacts = taskDetails?.client?.client_contact_list;
	const workflowContacts = workflowSubmission?.props?.sections[0]?.columns?.filter((col) =>
		col.columnComponents?.find((compo) => compo.componentType === 'contact-pick-list'),
	)[0]?.columnComponents[0]?.value;

	const handleTaskStatus = (TASK_STATUSE, ref) => {
		setButtonLoading((prevLoading) => ({ ...prevLoading, [ref]: true }));
		dispatch(Actions.updateTaskDetails(id, { status: TASK_STATUSE }))
			.then(() => {
				setButtonLoading((prevLoading) => ({ ...prevLoading, [ref]: false }));
			})
			.catch((error) => {
				setButtonLoading((prevLoading) => ({ ...prevLoading, [ref]: false }));
				dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
			});
	};

	const handleCompleteStatus = (ref) => () => {
		handleTaskStatus(TASK_STATUSES.COMPLETED, ref);
		handleTaskTimerRef.current(TRACKING_STATUS.ENDED);
	};

	const handleCompleteAlert = (taskDetails) => {
		toggleTaskAlert();
		const message = dependentTaskCompleteAlert(
			assignee?.user_id,
			taskDetails?.depend_on_task?.assignee_user_id,
			taskDetails?.depend_on_task?.title,
		);
		setTaskCompleteAlertMessage(message);
	};

	const handleBlockedStatus = (ref) => () => {
		handleTaskStatus(TASK_STATUSES.BLOCKED, ref);
		handleTaskTimerRef.current(TRACKING_STATUS.ENDED);
	};
	const handleProgressStatus = (ref) => () => {
		handleTaskStatus(TASK_STATUSES.IN_PROGRESS, ref);
	};

	const handleReviseStatus = (ref) => () => {
		handleTaskStatus(TASK_STATUSES.IN_PROGRESS, ref);
		handleTaskTimerRef.current(TRACKING_STATUS.ENDED);
	};

	const handleRequestApprovalStatus = (ref) => () => {
		handleTaskStatus(TASK_STATUSES.APPROVAL_PENDING, ref);
		handleTaskTimerRef.current(TRACKING_STATUS.ENDED);
	};

	var isTimeTrackingDateValid = false;
	var is_tentative = true;
	if (taskDetails && task_date && today_date) {
		isTimeTrackingDateValid = moment(task_date).isSameOrBefore(moment(today_date));
		is_tentative = !(moment(task_date).isSameOrBefore(moment(today_date)));
	}

	const handleUnblockedStatus = (ref) => () => {
		if (!is_tentative && isTimeTrackingDateValid && user.userId === assignee?.user_id) {
			handleTaskStatus(TASK_STATUSES.IN_PROGRESS, ref);
		} else {
			handleTaskStatus(TASK_STATUSES.NEW, ref);
		}
	};

	const workflowNotes = workflowSubmission?.props?.sections
		?.find((section) => section?.componentName == 'Notes')
		?.columns?.flatMap((column) => column?.columnComponents)
		?.find((columnComponent) => columnComponent?.componentType === 'textarea')?.value;

	const is_time_tracking = useMemo(() => {
		return Boolean(
			(taskDetails &&
				!is_tentative &&
				isTimeTrackingDateValid &&
				user?.userId === assignee?.user_profile?.id &&
				taskDetails?.status !== TASK_STATUSES?.APPROVAL_PENDING &&
				taskDetails?.status !== TASK_STATUSES?.BLOCKED &&
				taskDetails?.status !== TASK_STATUSES?.COMPLETED) ||
				((taskDetails?.status == TASK_STATUSES?.COMPLETED ||
					taskDetails?.status === TASK_STATUSES?.APPROVAL_PENDING) &&
					user.userId === taskDetails?.approval_from &&
					!is_tentative &&
					isTimeTrackingDateValid &&
					taskDetails?.status !== TASK_STATUSES?.COMPLETED),
		);
	}, [taskDetails?.id, taskDetails?.status, assignee, user]);

	handleTaskTimerRef.current = useCallback(
		(tracking_status) => {
			const { formattedTimeZone } = useTimeZone();
			const loggedDate =
				tracking_status == TRACKING_STATUS.STARTED
					? moment().format('YYYY-MM-DD')
					: moment(taskTimerData?.logged_date).format('YYYY-MM-DD');

			if (is_time_tracking) {
				dispatch(
					Actions.handleTaskTimer(
						{
							task_id: taskDetails?.id,
							user_id: user?.userId,
							consumed_time: JSON.stringify(Math.floor(timeLoggedRef.current)),
							logged_date: loggedDate,
							timezone: formattedTimeZone,
						},
						undefined,
						tracking_status,
					),
				);
			}
		},
		[dispatch, timeLoggedRef.current, is_time_tracking, taskTimerData],
	);

	const handleTaskTimerFunction = useCallback(
		(tracking_status) => {
			handleTaskTimerRef.current(tracking_status);
		},
		[handleTaskTimerRef],
	);

	useEffect(() => {
		if (is_time_tracking) {
			handleTaskTimerFunction(TRACKING_STATUS.STARTED);
		}
	}, [handleTaskTimerFunction, is_time_tracking]);

	useEffect(() => {
		let intervalId = null;
		if (is_time_tracking) {
			intervalId = setInterval(() => {
				handleTaskTimerFunction(TRACKING_STATUS.IN_PROGRESS);
			}, 10000);
		} else {
			clearInterval(intervalId);
		}
		return () => clearInterval(intervalId);
	}, [handleTaskTimerFunction, is_time_tracking]);

	useEffect(() => {
		return () => {
			// dispatch(Actions.resetTaskDetails());
			dispatch(Actions.resetTaskTimer());
			dispatch(Actions.resetComment());
			dispatch(Actions.resetTaskAssignee());
			dispatch(clientActions.updateFolderData([]));
			dispatch(Actions.setTaskCreatedError(null));
		};
	}, [dispatch]);

	useEffect(() => {
		dispatch(Actions.resetTaskTimer());
	}, [taskDetails?.depend_on_task, taskDetails?.dependent_task]);

	useEffect(() => {
		taskDetails &&
			taskDetails?.client?.team_client_permissions?.length > 0 &&
			dispatch(
				teamActions.getTeamMembers(
					taskDetails?.client?.team_client_permissions[0]?.team_id,
				),
			);
	}, [taskDetails?.client]);

	const handleSaveAdHocTask = (update_future = false, data, callback) => {
		let formDetails = {};
		let taskStatus = taskDetails?.status;
		if (
			taskDetails &&
			weeklyClientTasks?.length > 0 &&
			!is_tentative &&
			task_date == today_date &&
			user?.userId === data?.assignee_user_id &&
			taskDetails?.status == 'NEW'
		) {
			taskStatus = 'IN_PROGRESS';
		}
		formDetails = {
			id: data?.id,
			type: taskDetails?.my_task?.type,
			description: taskDetails?.description,
			notes: taskDetails?.notes,
			title: data?.title,
			client_id: taskDetails?.client_id == null ? null : Number(taskDetails?.client_id),
			budgeted_hours: Number(data?.budgeted_hours),
			assignee_list: Number(data?.assignee_user_id) ? [Number(data?.assignee_user_id)] : [],
			assignee_user_id: data?.assignee_user_id ? Number(data?.assignee_user_id) : null,
			approval_from: data?.approval_from ? Number(data?.approval_from) : null,
			is_approval_required: data.approval_from ? true : false,
			start_date:
				moment(data?.start_date).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD'),
			extended_date: data.extended_date || null,
			is_billable: taskDetails?.is_billable,
			task_type: taskDetails?.my_task?.task_type,
			status: taskStatus,
			end_on: taskDetails?.my_task?.end_on,
			required_days: Number(data?.required_days),
			repeat_type: taskDetails?.repeat_type,
			repeat_interval:
				taskDetails?.repeat_interval == null ? null : Number(taskDetails?.repeat_interval),
			recur_preference: taskDetails?.my_task?.recur_preference,
			is_monthly_on_day: taskDetails?.my_task?.is_monthly_on_day,
		};
		dispatch(Actions.updateMeetingTaskDetails(formDetails, update_future))
			.then(() => {
				callback && callback(false);
			})
			.catch(() => {
				callback && callback(false);
			});
	};

	const handleSaveTaskDetails = (data, assignee, callback) => {
		if (data?.assignee_user_id != assignee?.user_id) {
			dispatch(
				Actions.updateTaskAssignees(assignee?.id, {
					assignees: data?.assignee_user_id
						? [
								{
									user_id: data?.assignee_user_id,
									task_id: data.id,
								},
						  ]
						: [],
				}),
			)
				.then(() => {})
				.catch((error) => {
					dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
				});
		}
		isAdhoc
			? handleSaveAdHocTask(false, data, callback)
			: dispatch(
					Actions.updateTaskDetails(data?.id, {
						...data,
					}),
			  )
					.then((response) => {
						callback && callback(false);
					})
					.catch((error) => {
						callback && callback(false);
						dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
					});
	};

	const tabs = [
		{
			name: 'Resources',
			id: 1,
			display: true,
		},
		{
			name: 'Scheduling',
			id: 5,
			display: true,
		},
		{
			name: 'Time Tracking',
			id: 2,
			display: true,
		},
		{
			name: 'Contacts',
			id: 3,
			display: true,
		},
		{
			name: 'Folder & Files',
			id: 4,
			display: true,
		},
	];

	const [tab, setTab] = useState(tabs[0]);
	const changeTab = (item) => {
		setTab(item);
	};

	if (isAdhoc) {
		const indecies = [0, 3, 4];
		indecies.sort((a, b) => b - a);
		indecies.forEach((index) => tabs.splice(index, 1));
	}

	useEffect(() => {
		setTab(tabs[0]);
	}, [tabs?.length]);

	const handleDependentTaskClick = (task) => {
		dispatch(Actions.resetTaskDetails());
		dispatch(Actions.resetTaskAssignee());
		setTab(tabs[0]);
		handleTaskClick(task?.id, detailsModal.CLIENT);
		handleTaskTimerRef.current(TRACKING_STATUS.ENDED);
		timeLoggedRef.current = 0;
	};

	const handleOpeInNewTab = () => {
		window.open(window.location.href, '_blank');
	};

	const handleMouseDown = useCallback(
		(e) => {
			if (e.button !== 0) {
				return;
			}
			if (ref.current) {
				dragOffset.current = {
					x: e.clientX - ref.current.getBoundingClientRect().left,
					y: e.clientY - ref.current.getBoundingClientRect().top,
				};
				document.addEventListener('mousemove', handleMouseMove);
				document.addEventListener('mouseup', handleMouseUp);
				isDrag.current = false;
			}
		},
		[taskDetails],
	);

	const handleMouseMove = useCallback(
		(e) => {
			if (ref.current) {
				const newX = e.clientX - dragOffset.current.x;
				const newY = e.clientY - dragOffset.current.y;

				if (newX !== dragOffset.current.x || newY !== dragOffset.current.y) {
					isDrag.current = true;
				}
				if (isDrag.current) {
					const viewportWidth = window.innerWidth;
					const viewportHeight = window.innerHeight;

					const cardWidth = ref.current.offsetWidth;
					const cardHeight = ref.current.offsetHeight;

					const leftBoundary = 0;
					const rightBoundary = viewportWidth - cardWidth;
					const topBoundary = 0;
					const bottomBoundary = viewportHeight - cardHeight;

					const clampedX = Math.max(leftBoundary, Math.min(newX, rightBoundary));
					const clampedY = Math.max(topBoundary, Math.min(newY, bottomBoundary));

					ref.current.style.left = `${clampedX}px`;
					ref.current.style.top = `${clampedY}px`;
				}
			}
		},
		[taskDetails],
	);

	const active_task_id = useSessionStorage('active_task_id');
	const history = useHistory();

	const handleMouseUp = useCallback(() => {
		if (!isDrag.current) {
			maximizeTask();
		}
		document.removeEventListener('mousemove', handleMouseMove);
		document.removeEventListener('mouseup', handleMouseUp);
	}, [taskDetails]);

	const maximizeTask = () => {
		ref.current.style.right = '70px';
		ref.current.style.top = '55px';
		ref.current.style.left = null;
		dispatch(Actions.setTaskModalMinimize(false));
		active_task_id.setSessionStorage(taskDetails?.id);
		history.push(`${location.pathname}?task_id=${taskDetails?.id}`);
	};

	const Notes = [
		{
			id: 1,
			title: 'Workflow Notes',
			name: 'worklfow_notes',
			content: workflowNotes,
			editExpand: false,
		},
		{
			id: 2,
			title: 'Instructions',
			name: 'description',
			content: taskDetails?.description,
			editExpand: true,
		},
		{
			id: 3,
			title: 'Task Notes',
			name: 'notes',
			content: taskDetails?.notes,
			editExpand: true,
		},
	];

	isAdhoc && Notes.shift();

	useEffect(() => {
		if (attached_ids?.length) {
			dispatch(clientActions.getMultipleFoldersAndFiles(attached_ids));
		}
	}, [folderId]);

	useEffect(() => {
		dispatch(userActions.getUsers());
		dispatch(userActions.getAllTenantUsers());
	}, []);

	const managerID = Member?.teamManagerId;
	// If logged in user is team manager in the team for relevant opened task, it can change status of task

	const teamClientPermissions = taskDetails?.client?.team_client_permissions;

	const taskClientTeamId =
		teamClientPermissions && teamClientPermissions.length > 0
			? teamClientPermissions[0]?.team_id
			: '';

	const userTeamManagerId = user.user_roles
		?.find((role) => role.role_id === managerID)
		?.role_scopes?.find((scope) => scope.team_id === taskClientTeamId)?.team_id;
	const isUserClientsTeamManager =
		taskClientTeamId && userTeamManagerId && taskClientTeamId === userTeamManagerId
			? true
			: false;

	const client_workflow_id = isAdhoc
		? taskDetails?.my_task?.client_workflow_id
		: taskDetails?.client_workflow?.id;

	useEffect(() => {
		if (taskDetails) {
			dispatch(Actions.getTaskAssignees(taskDetails?.id));
		}
	}, [taskDetails?.id]);

	useEffect(() => {
		if (taskDetails) {
			setCommentLoading(true);
			dispatch(Actions.getTaskComments(taskDetails?.id))
				.then(() => {
					setCommentLoading(false);
				})
				.catch(() => {
					setCommentLoading(false);
				});
		}
	}, [taskDetails?.id]);

	useEffect(() => {
		if (taskDetails) {
			dispatch(Actions.setResourceLoading(true));
			const apiPromise = [
				dispatch(Actions.getClientWorkflowSubmission(client_workflow_id)),
				dispatch(Actions.getClientSoftwareStacks(taskDetails?.client_id)),
			];
			Promise.all(apiPromise).finally(() => {
				dispatch(Actions.setResourceLoading(false));
			});
		}
	}, [taskDetails?.id]);

	useEffect(() => {
		if (!window.location.pathname?.includes('workspace')) {
			if (event_source) {
				EventSource.addListener(
					SERVER_SENT_EVENTS.WORKSPACE_UPDATED,
					evnetParseHandler,
					event_source,
				);
			} else if (user?.userId) {
				EventSource.connect();
			}
		}
	}, [event_source, user?.userId, window.location.pathname]);

	useEffect(() => {
		if (taskDetails) {
			dispatch(Actions.getTaskActivity());
			dispatch(Actions.updateTaskActivity(taskDetails?.id, 'opened'));
			dispatch(Actions.setTaskModalMinimize(false));
		}
	}, [taskDetails?.id]);

	useEffect(() => {
		dispatch(Actions.setIsTimeTracking(is_time_tracking));
	}, [is_time_tracking]);

	return (
		<>
			<div
				tabIndex={-1}
				ref={ref}
				className={
					isTaskMinimized
						? 'task-bar d-flex align-items-center'
						: 'task-details-container'
				}
				style={{
					transition: ' width 0.3s ease, height 0.3s ease',
					backgroundColor:
						isTaskMinimized &&
						(taskDetails?.client_workflow?.color ||
							taskDetails?.my_task?.client_workflow?.color ||
							'#fb7a03'),
				}}
				onMouseDown={isTaskMinimized ? handleMouseDown : undefined}
			>
				{isTaskMinimized ? (
					<TaskDetailsBar
						isTimeTracking={is_time_tracking}
						backgroundColor={
							taskDetails?.client_workflow?.color ||
							taskDetails?.my_task?.client_workflow?.color ||
							'#fb7a03'
						}
						taskStatus={taskDetails?.status}
						consumedTime={consumedTime}
						timeLoggedRef={timeLoggedRef}
						taskTitle={taskDetails?.title}
						assigneeName={assignee?.user_profile?.full_name || 'Not Assigned'}
						assigneeProfileUrl={assignee?.user_profile?.image_url}
						handleModalClose={onModalClose}
					/>
				) : (
					<>
						{taskDetails !== null && Object.keys(taskDetails)?.length > 0 ? (
							<>
								{expandView.isExpanded && (
									<ExpandableNotesView
										content={expandView?.content}
										title={expandView?.title}
										isEdit={expandView?.isEdit}
										name={expandView?.name}
										setExpandView={setExpandView}
										workflowNotes={expandView.title == 'Workflow Notes'}
										taskId={taskDetails?.id}
										isAdhoc={isAdhoc}
									/>
								)}
								<>
									<div
										className={`${
											expandView.isExpanded && 'd-none'
										} task-details-header`}
										style={{
											backgroundColor:
												taskDetails?.client_workflow?.color ||
												taskDetails?.my_task?.client_workflow?.color ||
												'#fb7a03',
										}}
									>
										<div
											className='d-flex flex-column align-items-start'
											style={{ gap: 4 }}
										>
											<div className='d-flex align-items-center'>
												<span className='HeadlineThreeBold text-white'>
													{taskDetails?.title}
												</span>
											</div>
											<span className='BodyOneLight text-white'>
												{taskDetails?.client_workflow?.display_name ||
													taskDetails?.my_task?.client_workflow
														?.display_name ||
													'Ad hoc Task'}
											</span>
											<div
												className='d-flex align-items-center text-white'
												style={{ gap: '5px' }}
											>
												<span className='BodyOneLight d-flex'>
													Assignee:{' '}
												</span>
												<span className='BodyOneLight'>
													{assignee?.user_profile?.full_name ||
														'Unassigned'}
												</span>

												<div
													style={{
														marginLeft: '8px',
														marginRight: '8px',
													}}
												>
													<Avatar
														data-toggle='tooltip'
														data-placement='top'
														title={
															assignee?.user_profile?.full_name ||
															'Not Assigned'
														}
														sx={{
															bgcolor: `#FEF2E7`,
															color: `#333`,
															width: '18px',
															height: '18px',
                                                            fontSize: '0.7rem'
														}}
														alt='User'
														src={assignee?.user_profile?.image_url}
														srcSet={null}
													>
                                                        {getAvatarLabel(assignee?.user_profile?.full_name)}
                                                        </Avatar>
												</div>
											</div>
											{taskDetails?.approval_from !== null && (
												<div
													className='d-flex align-items-center text-white'
													style={{ gap: '5px' }}
												>
													<span className='BodyOneLight d-flex'>
														Require Approval:{' '}
													</span>
													<span className='BodyOneLight'>
														{teamMembers?.find(
															(teamMember) =>
																teamMember?.user_id ==
																taskDetails?.approval_from,
														)?.name || 'Unassigned'}
													</span>
												</div>
											)}
										</div>
										<div>
											<div
												className='d-flex justify-content-end mb-1'
												style={{ gap: '12px' }}
											>
												<Minimize
													className='pointer'
													onClick={handleModalMinimze}
												/>
												<ArrowOut
													className='pointer'
													onClick={handleOpeInNewTab}
												/>
												<Close className='pointer' onClick={onModalClose} />
											</div>
											<div className='d-flex' style={{ gap: 20 }}>
												<div className='d-flex flex-column align-items-center'>
													<span className='BodyOneLight text-white'>
														Budgeted hours
													</span>
													<span className='BodyOneLight text-white'>
														{convertDecimalToTime(
															taskDetails?.budgeted_hours,
														)}
													</span>
												</div>
												<div
													style={{
														width: '1px',
														height: '50px',
														backgroundColor: 'white',
														borderRadius: '50%',
													}}
												></div>
												<div className='d-flex flex-column align-items-center'>
													<div>
														<span className='BodyOneLight pb-1 text-white'>
															{
																TASK__DISPLAY_STATUSES[
																	taskDetails?.status
																]
															}
														</span>
													</div>
													{is_time_tracking && (
														<>
															<div>
																<span className='BodyTwoLight'>
																	<TimeTracking
																		consumedTime={consumedTime}
																		timeLoggedRef={
																			timeLoggedRef
																		}
																	/>
																</span>
															</div>
														</>
													)}
												</div>
												<div
													style={{
														width: '1px',
														height: '50px',
														backgroundColor: 'white',
													}}
												></div>
												<div className='d-flex flex-column align-items-center'>
													<span className='BodyOneLight text-white'>
														Due Date
													</span>
													<span className='BodyOneLight text-white'>
														{moment(taskDetails?.due_date).format(
															date_format,
														)}
													</span>
												</div>
											</div>
										</div>
									</div>
									<div
										className={`${
											expandView.isExpanded && 'd-none'
										} task-details-body`}
									>
										<div className='left-side-container'>
											<div>
												<span
													className='HeadlineThreeBook'
													style={{ paddingBottom: '12px' }}
												>
													{taskDetails?.client?.dba?.trim() == ''
														? taskDetails?.client?.company_name
														: taskDetails?.client?.dba}
												</span>
											</div>
											{Notes?.map((item) => {
												return (
													<NewTaskDetailsModalNotes
														key={item?.id}
														title={item?.title}
														name={item.name}
														content={item?.content}
														editExpand={item?.editExpand}
														setExpandView={setExpandView}
													/>
												);
											})}
										</div>

										{expandComment ? (
											<div
												className='right-side-container'
												id='label-file-upload'
												htmlFor='input-file-upload'
												style={{
													position: 'relative',
													zIndex: '10',
												}}
											>
												<TaskModalComments
													assignee_user_id={assignee?.user_id}
													id={id}
													setExpandComment={setExpandComment}
													expandComment={expandComment}
													folderId={folderId}
													isAdhoc={isAdhoc}
													tabs={tabs}
													modalIsOpen={modalIsOpen}
													toggleModal={toggleModal}
													ModalName={ModalName}
													handleModalOpen={handleModalOpen}
													files={files}
													setFiles={setFiles}
													selectedFiles={selectedFiles}
													setSelectedFiles={setSelectedFiles}
													selectedItem={selectedItem}
													displayName={
														taskDetails?.client_workflow?.display_name
													}
													loading={commentLoading}
												/>
											</div>
										) : (
											<div className='right-side-container'>
												<div className='task-tabs-container clearfix'>
													<div
														className='d-flex align-items-center justify-content-between hide-scrollbar py-1'
														style={{
															overflowX: 'auto',
															overflowY: 'hidden',
															gap: '12px',
														}}
													>
														<div className='task-tabs d-flex w-100'>
															{tabs
																.filter((item) => item?.display)
																.map((item) => {
																	return (
																		<div
																			onClick={
																				!taskDetails
																					? null
																					: () => {
																							changeTab(
																								item,
																							);
																					  }
																			}
																			key={item?.id}
																			className={`task-tab ${
																				tab?.id ==
																					item?.id &&
																				'active-tab'
																			} `}
																		>
																			<span className='BodyTwoBold pt-1'>
																				{item?.name}
																			</span>
																		</div>
																	);
																})}
														</div>
														<RequestDocumentInvite
															parentFolderId={folderId}
															clientId={taskDetails?.client_id}
															clientWorkflowName={
																taskDetails?.client_workflow
																	?.display_name
															}
															isUserClientsTeamManager={
																isUserClientsTeamManager
															}
															isAdhoc={isAdhoc}
														/>
													</div>
													<div className='task-tabs-details-container'>
														{tab?.id == 1 && (
															<ResourcesTab
																accountTypes={accountTypes}
															/>
														)}
														{tab?.id == 2 && (
															<TimeTrackingTab
																taskDetails={taskDetails}
															/>
														)}
														{tab?.id == 3 && (
															<ContactsTable
																totalContacts={totalContacts}
																workflowContacts={workflowContacts}
															/>
														)}
														{tab?.id == 4 && (
															<DocumentStorage
																headers={
																	ModalName ==
																		'CHANGE_FOLDER_MODAL' &&
																	modalIsOpen
																		? headersForModal
																		: headers
																}
																clientWorkflow={
																	taskDetails
																		?.client_workflow_task
																		?.client_workflow
																}
																handleFolderClick={
																	handleFolderClick
																}
																taskModal={true}
																selectedItem={selectedItem}
																setSelectedItem={setSelectedItem}
																hideFile={
																	ModalName ==
																		'CHANGE_FOLDER_MODAL' &&
																	modalIsOpen
																		? true
																		: false
																}
																handleAlertModal={handleAlertModal}
															/>
														)}
														{tab?.id == 5 && (
															<TaskScheduling
																budgeted_hours={
																	taskDetails?.budgeted_hours
																}
																start_date={taskDetails?.start_date}
																due_date={taskDetails?.due_date}
																isAdhoc={isAdhoc}
																required_days={
																	taskDetails?.my_task
																		?.required_days
																}
																task_id={taskDetails?.id}
																extended_date={
																	taskDetails?.extended_date
																}
																title={taskDetails?.title}
																task_status={taskDetails?.status}
																task_repeat_type={
																	taskDetails?.repeat_type
																}
																team_id={
																	taskDetails?.client
																		?.team_client_permissions
																		?.length > 0
																		? taskDetails?.client
																				?.team_client_permissions[0]
																				?.team_id
																		: null
																}
																approval_from={
																	taskDetails?.approval_from
																}
																assignee={assignee}
																handleSaveDetails={
																	handleSaveTaskDetails
																}
															/>
														)}
													</div>
												</div>
												{/* )} */}

												{!isAdhoc && (
													<div className='task-dependencies-container'>
														<TaskModalContainer title='Dependencies'>
															<div
																className='d-flex'
																style={{
																	paddingTop: '6px',
																}}
															>
																{taskDetails?.dependent_task_id !==
																	null && (
																	<div
																		className='dependent-task-card d-flex flex-column align-items-start'
																		onClick={() =>
																			handleDependentTaskClick(
																				taskDetails?.depend_on_task,
																			)
																		}
																	>
																		<div className='d-flex w-100 align-items-center justify-content-between'>
																			<Tooltip
																				title={
																					taskDetails
																						?.depend_on_task
																						?.title
																				}
																			>
																				<span
																					style={{
																						width: '40%',
																					}}
																					className='ellipse BodyTwoLight'
																				>
																					{
																						taskDetails
																							?.depend_on_task
																							?.title
																					}
																				</span>
																			</Tooltip>
																			<div>
																				<Avatar
																					data-toggle='tooltip'
																					data-placement='top'
																					title={
																						taskDetails
																							?.depend_on_task
																							?.user_profile
																							?.full_name ||
																						'Not Assigned'
																					}
																					sx={{
																						bgcolor: `#FEF2E7`,
																						color: `#333`,
																						width: '18px',
																						height: '18px',
																					}}
																					alt='User'
																					src={
																						taskDetails
																							?.depend_on_task
																							?.user_profile
																							?.image_url
																					}
																					srcSet={null}
																				/>
																			</div>
																			<div
																				className={`status status-${taskDetails?.depend_on_task?.status} d-flex align-items-center justify-content-center`}
																			>
																				<span className='BodyThreeBold'>
																					{
																						TASK__DISPLAY_STATUSES[
																							taskDetails
																								?.depend_on_task
																								?.status
																						]
																					}
																				</span>
																			</div>
																		</div>
																	</div>
																)}
																<div className='dependent-current-task-card'>
																	<Tooltip
																		title={taskDetails?.title}
																	>
																		<span
																			style={{
																				width: '40%',
																			}}
																			className='ellipse BodyTwoBold current-task-text'
																		>
																			{taskDetails?.title}
																		</span>
																	</Tooltip>
																</div>
																{taskDetails?.dependent_task && (
																	<div
																		className='dependent-task-card d-flex flex-column align-items-start'
																		style={{ gap: '5px' }}
																		onClick={() =>
																			handleDependentTaskClick(
																				taskDetails?.dependent_task,
																			)
																		}
																	>
																		<div className='d-flex w-100 align-items-center justify-content-between'>
																			<Tooltip
																				title={
																					taskDetails
																						?.dependent_task
																						?.title
																				}
																			>
																				<span
																					style={{
																						width: '40%',
																					}}
																					className='ellipse BodyTwoLight'
																				>
																					{
																						taskDetails
																							?.dependent_task
																							?.title
																					}
																				</span>
																			</Tooltip>
																			<div>
																				<Avatar
																					data-toggle='tooltip'
																					data-placement='top'
																					title={
																						taskDetails
																							?.dependent_task
																							?.user_profile
																							?.full_name ||
																						'Not Assigned'
																					}
																					sx={{
																						bgcolor: `#FEF2E7`,
																						color: `#333`,
																						width: '18px',
																						height: '18px',
																					}}
																					alt='User'
																					src={
																						taskDetails
																							?.dependent_task
																							?.user_profile
																							?.image_url
																					}
																					srcSet={null}
																				/>
																			</div>
																			<div
																				className={`status status-${taskDetails?.dependent_task?.status} d-flex align-items-center justify-content-center`}
																			>
																				<span className='BodyThreeBold'>
																					{
																						TASK__DISPLAY_STATUSES[
																							taskDetails
																								?.dependent_task
																								?.status
																						]
																					}
																				</span>
																			</div>
																		</div>
																	</div>
																)}
															</div>
														</TaskModalContainer>
													</div>
												)}
												<div className='task-comments-container mt-2'>
													<div className='task-comments-container'>
														<TaskModalComments
															assignee_user_id={assignee?.user_id}
															id={id}
															setExpandComment={setExpandComment}
															expandComment={expandComment}
															folderId={folderId}
															isAdhoc={isAdhoc}
															tabs={tabs}
															modalIsOpen={modalIsOpen}
															toggleModal={toggleModal}
															ModalName={ModalName}
															handleModalOpen={handleModalOpen}
															files={files}
															setFiles={setFiles}
															selectedFiles={selectedFiles}
															setSelectedFiles={setSelectedFiles}
															selectedItem={selectedItem}
															displayName={
																taskDetails?.client_workflow
																	?.display_name
															}
															loading={commentLoading}
														/>
													</div>
												</div>
											</div>
										)}
									</div>
									<div
										className={`${
											expandView.isExpanded && 'd-none'
										} task-details-footer flex-row-reverse`}
									>
										<>
											<div className='d-flex'>
												{taskDetails &&
													taskDetails.status === TASK_STATUSES.BLOCKED &&
													(user?.userRole?.id === 16 ||
														user?.userRole?.id === 12 ||
														isUserClientsTeamManager) && (
														<div
															onClick={handleUnblockedStatus(
																'UNBLOCK',
															)}
															className='pl-1 pr-1'
														>
															<ButtonSquare
																style={{
																	backgroundColor: '#FEF2E7',
																	color: 'black',
																}}
																className='full-width'
																disabled={buttonLoading['UNBLOCK']}
															>
																{buttonLoading['UNBLOCK'] ? (
																	<CircularProgress
																		size={20}
																		sx={{
																			color: '#FB7A03',
																		}}
																		disableShrink
																	/>
																) : (
																	'Unblock'
																)}
															</ButtonSquare>
														</div>
													)}
												{taskDetails &&
													taskDetails.status ===
														TASK_STATUSES.COMPLETED &&
													user?.userRole?.id !==
														Member.externalCollaborator && (
														<div
															className='pl-1 pr-1'
															onClick={handleProgressStatus(
																'UNCOMPLETE',
															)}
														>
															<ButtonSquare
																style={{
																	backgroundColor: '#FB7A03',
																	color: 'white',
																}}
																className={'full-width'}
																disabled={
																	buttonLoading['UNCOMPLETE']
																}
															>
																{buttonLoading['UNCOMPLETE'] ? (
																	<CircularProgress
																		size={20}
																		sx={{
																			color: 'white',
																		}}
																		disableShrink
																	/>
																) : (
																	'Uncomplete'
																)}
															</ButtonSquare>
														</div>
													)}
												{taskDetails &&
													// user.userId === assignee?.user_profile?.id &&
													taskDetails?.status !== TASK_STATUSES.BLOCKED &&
													taskDetails?.status !==
														TASK_STATUSES.COMPLETED && (
														<div className='d-flex w-100 justify-content-between '>
															{(user.userId === assignee?.user_id ||
																user?.userRole?.id === 16 ||
																user?.userRole?.id === 12 ||
																user?.userId ===
																	taskDetails?.approval_from ||
																isUserClientsTeamManager) && (
																<>
																	<div
																		style={{
																			marginRight: '16px',
																		}}
																		onClick={
																			is_tentative ||
																			taskDetails.status ===
																				TASK_STATUSES.APPROVAL_PENDING ||
																			taskDetails.status ===
																				TASK_STATUSES.COMPLETED
																				? () => {}
																				: handleBlockedStatus(
																						'BLOCKED',
																				  )
																		}
																	>
																		<ButtonSquare
																			style={{
																				backgroundColor:
																					'#FB7A03',
																				color: 'white',
																			}}
																			className={
																				is_tentative ||
																				taskDetails.status ===
																					TASK_STATUSES.APPROVAL_PENDING ||
																				taskDetails.status ==
																					TASK_STATUSES.COMPLETED
																					? 'full-width opacity-50'
																					: 'full-width'
																			}
																			disabled={
																				buttonLoading[
																					'BLOCKED'
																				]
																			}
																		>
																			{buttonLoading[
																				'BLOCKED'
																			] ? (
																				<CircularProgress
																					size={20}
																					sx={{
																						color: 'white',
																					}}
																					disableShrink
																				/>
																			) : (
																				'Block'
																			)}
																		</ButtonSquare>
																	</div>
																	{user?.userId ===
																		taskDetails?.approval_from &&
																		taskDetails?.status ===
																			TASK_STATUSES.APPROVAL_PENDING && (
																			<div
																				onClick={handleReviseStatus(
																					'REVISE',
																				)}
																				style={{
																					marginRight:
																						'16px',
																				}}
																			>
																				<ButtonSquare
																					style={{
																						backgroundColor:
																							'#FB7A03',
																						color: 'white',
																					}}
																					className='full-width'
																					disabled={
																						buttonLoading[
																							'REVISE'
																						] ||
																						is_tentative
																					}
																				>
																					{buttonLoading[
																						'REVISE'
																					] ? (
																						<CircularProgress
																							size={
																								20
																							}
																							sx={{
																								color: 'white',
																							}}
																							disableShrink
																						/>
																					) : (
																						'Revise'
																					)}
																				</ButtonSquare>
																			</div>
																		)}
																	{taskDetails?.is_approval_required &&
																	user?.userId ===
																		assignee?.user_id ? (
																		<div
																			onClick={
																				taskDetails.status ===
																					TASK_STATUSES.APPROVAL_PENDING ||
																				is_tentative
																					? () => {}
																					: handleRequestApprovalStatus(
																							'REQUEST_APPROVAL',
																					  )
																			}
																		>
																			<ButtonSquare
																				className={
																					taskDetails.status ===
																						TASK_STATUSES.APPROVAL_PENDING ||
																					is_tentative
																						? 'full-width opacity-50'
																						: 'full-width'
																				}
																				disabled={
																					buttonLoading[
																						'REQUEST_APPROVAL'
																					]
																				}
																			>
																				{buttonLoading[
																					'REQUEST_APPROVAL'
																				] ? (
																					<CircularProgress
																						size={20}
																						sx={{
																							color: 'white',
																						}}
																						disableShrink
																					/>
																				) : (
																					'Request Approval'
																				)}
																			</ButtonSquare>
																		</div>
																	) : (
																		<div
																			onClick={
																				is_tentative
																					? () => {}
																					: taskDetails.status ===
																							TASK_STATUSES.COMPLETED ||
																					  (taskDetails?.depend_on_task &&
																							taskDetails
																								?.depend_on_task
																								?.status !==
																								TASK_STATUSES.COMPLETED)
																					? () =>
																							handleCompleteAlert(
																								taskDetails,
																							)
																					: handleCompleteStatus(
																							'COMPLETE',
																					  )
																			}
																		>
																			<ButtonSquare
																				style={{
																					backgroundColor:
																						'#FB7A03',
																					color: 'white',
																				}}
																				className={
																					is_tentative
																						? 'full-width opacity-50'
																						: taskDetails.status ==
																								TASK_STATUSES.COMPLETED ||
																						  (taskDetails?.depend_on_task &&
																								taskDetails
																									?.depend_on_task
																									?.status !==
																									TASK_STATUSES.COMPLETED)
																						? 'full-width'
																						: 'full-width'
																				}
																				disabled={
																					buttonLoading[
																						'COMPLETE'
																					]
																				}
																			>
																				{buttonLoading[
																					'COMPLETE'
																				] ? (
																					<CircularProgress
																						size={20}
																						sx={{
																							color: 'white',
																						}}
																						disableShrink
																					/>
																				) : user?.userId ===
																						taskDetails?.approval_from &&
																				  taskDetails?.status ===
																						TASK_STATUSES.APPROVAL_PENDING ? (
																					'Approve'
																				) : (
																					'Complete'
																				)}
																			</ButtonSquare>
																		</div>
																	)}
																</>
															)}
														</div>
													)}
											</div>
										</>
										<div className='d-flex'>
											{isDeleteAdhocAllowed && (
												<ButtonSquare
													style={{
														backgroundColor: '#EDEDED',
														color: 'black',
														marginRight: '20px',
													}}
													onClick={handleModalOpen(
														'ADD_HOC_DELETE_MODAL',
													)}
												>
													Delete
												</ButtonSquare>
											)}
											{/* <ButtonSquare
										style={{
											backgroundColor: '#EDEDED',
											color: 'black',
										}}
										clickHandler={() => {
											onModalClose();
											handleTaskTimerRef.current();
										}}
									>
										Close
									</ButtonSquare> */}
										</div>
									</div>
								</>

								<TaskAlert
									message={taskCompleteAlertMessage?.body}
									header={taskCompleteAlertMessage?.header}
									open={isTaskAlert}
									onDone={() => {
										handleDependentTaskClick(taskDetails?.depend_on_task);
										toggleTaskAlert();
									}}
									doneButton='Go to Proceeding Task'
									closeButton='Close'
									closeHandler={toggleTaskAlert}
								/>
							</>
						) : (
							<ContentLoader
								speed={2}
								width={'100%'}
								height={'100%'}
								viewBox='0 0 90% 90%'
								backgroundColor='#e6e6e6'
								foregroundColor='#C0BFBF'
							>
								<rect x='30' y='40' rx='4' ry='4' width='300' height='30' />
								<rect x='30' y='80' rx='4' ry='4' width='150' height='20' />
								<rect x='30' y='110' rx='4' ry='4' width='200' height='20' />
								<rect x='59%' y='40' rx='4' ry='4' width='600' height='100' />
								<rect x='30' y='180' rx='4' ry='4' width='30%' height='570' />
								<rect x='30' y='92%' rx='4' ry='4' width='200' height='40' />
								<rect x='48%' y='180' rx='4' ry='4' width='50%' height='200' />
								<rect x='48%' y='390' rx='4' ry='4' width='50%' height='100' />
								<rect x='48%' y='500' rx='4' ry='4' width='50%' height='250' />
								<rect x='88%' y='92%' rx='4' ry='4' width='150' height='40' />
							</ContentLoader>
						)}
						{ModalName == 'ADD_HOC_DELETE_MODAL' && (
							<Modal
								onClose={toggleModal}
								open={modalIsOpen}
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<>
									<AdHocTaskDeleteModal
										onClose={toggleModal}
										taskDetails={taskDetails}
										onModalClose={onModalClose}
									/>
								</>
							</Modal>
						)}
						{alertModal && (
							<FileExistModal
								modalIsOpen={alertModal}
								toggleModal={handleAlertModal}
							/>
						)}
					</>
				)}
			</div>
		</>
	);
});
export default memo(NewTaskDetailsModal);
